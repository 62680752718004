import React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

const ColorlibConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 9,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#BCBECE'
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#BCBECE'
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: '#4D4F5C',
    borderRadius: 1,
  },
}));


const steps = [
  { title: 'Paso 1', description: 'ELEGÍ TU PLAN' },
  { title: 'Paso 2', description: 'COMLPETÁ TUS DATOS' },
  { title: 'Paso 3', description: 'PAGÁ' },
];

export default function FormSteps({ activeStep, isDesktop }) {

  return (
    <Box
      sx={{
        py: { xs: 3 },
        width: '100%',
        maxWidth: '900px',
        mt: { xs: 0, xl: 5 },
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center'
      }}
    >
      <Stack sx={{ width: '100%' }} spacing={1}>
        <Stepper
          activeStep={activeStep}
          connector={<ColorlibConnector isDesktop={isDesktop} />}
          alternativeLabel
        >
          {steps.map((label) => (
            <Step key={label.title}>
              <StepLabel
                StepIconComponent={(props) => <CircleConnector {...props} />}
              >
                <Typography variant="subtitle2" align="center" color={'#696B77'} fontSize={'12px'}>
                  {label.description}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
    </Box>
  );
}

const CircleConnector = ({ completed, active, icon }) => {

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: (completed || active) ? '#BCBECE' : '#4D4F5C',
      width: 21,
      height: 21,
      borderRadius: 30,
      boxShadow: active ? '0px 4px 4px 0px #00000040' : null
    }}>
      <Typography textAlign={'center'} fontWeight={'700'} fontSize={'14px'} sx={{ zIndex: 4, paddingBottom: '2px' }}>
        {icon}
      </Typography>
    </Box>
  );
};
