import React from 'react';
//UI Components
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const ExtraFees = ({ name, item }) => {
  return (
    <Card elevation={0}>
      <CardContent sx={{ '&:last-child': { pb: 1 } }}>
        <Grid container>
          <Grid item xs={10}>
            <Typography variant="body2" fontWeight={400}>
              {name}
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Typography variant="body2" align="right" fontWeight={400}>
              {item}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ExtraFees;
