import React, { useRef, useEffect } from 'react';
import QRCode from 'qrcode-generator';

const QRCodeComponent = ({ url, size = 4 }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (url) {
      const qrcode = new QRCode(0, 'L');
      qrcode.addData(url);
      qrcode.make();

      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      const tileSize = size; // Tamaño de cada "tile" o módulo del QR en el canvas
      canvas.height = qrcode.getModuleCount() * tileSize;
      canvas.width = qrcode.getModuleCount() * tileSize;

      for (let row = 0; row < qrcode.getModuleCount(); row++) {
        for (let col = 0; col < qrcode.getModuleCount(); col++) {
          ctx.fillStyle = qrcode.isDark(row, col) ? '#d1d1d1' : '#2B2C33';
          ctx.fillRect(col * tileSize, row * tileSize, tileSize, tileSize);
        }
      }
    }
  }, [url, size]);

  return (
    <canvas ref={canvasRef} style={{ width: '400px', height: '400px' }}></canvas>
  );
};

export default QRCodeComponent;
