import { Typography } from '@mui/material';
import React from 'react';

const Text = ({ children, ...rest }) => {
  return (
    <Typography {...rest}>{children}</Typography>
  );
};

const Title = ({ children, ...rest }) => {
  return (
    <Text variant='h6' fontWeight={'900'} fontSize={'33px'} color={'#FFF'} {...rest}>{children}</Text>
  );
};

const Subtitle = ({ children, ...rest }) => {
  return (
    <Text variant={'subtitle1'} fontWeight={'500'} color={'#BCBECE'} fontSize={'18px'} lineHeight={'27px'} {...rest}>{children}</Text>
  );
};

const Feedback = ({ children, ...rest }) => {
  return (
    <Text variant={'subtitle1'} fontSize={'14px'} fontWeight={'400'} color={'#C4C4C4'}>{children}</Text>
  );
};

Text.Title = Title;
Text.Subtitle = Subtitle;
Text.Feedback = Feedback;

export default Text;