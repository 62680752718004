import React, { useEffect } from 'react';
//UI Components
import Box from '@mui/material/Box';
//React Hook Form
import { Controller } from 'react-hook-form';
import { CustomInput, SelectInput } from '../../../../components';
import { DocumentFormatCustom } from '../../../../components/Inputs/Inputs';
import { FormControl, Grid, InputLabel } from '@mui/material';
import { provincias } from '../../../../api/provincias';
import Text from '../../../../components/Text/Text';

const AddressData = ({ control, errors, touchedFields }) => {

  return (
    <Box sx={{ py: 1 }}>
      <Text.Subtitle fontWeight={'400'} sx={{ mb: { xs: 1, xl: 2 } }}>Domicilio</Text.Subtitle>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            name="domicilio.calle"
            control={control}
            render={({ field }) => (
              <CustomInput
                label="Calle"
                errors={errors?.domicilio?.calle}
                field={field}
                width={'100%'}
                isValid={touchedFields?.domicilio?.calle}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Controller
            name="domicilio.altura"
            control={control}
            render={({ field }) => (
              <CustomInput
                label="Número"
                errors={errors?.domicilio?.altura}
                field={field}
                width={'100%'}
                isValid={touchedFields?.domicilio?.altura}
                inputComponent={DocumentFormatCustom}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Controller
            name="domicilio.apto_lote"
            control={control}
            render={({ field }) => (
              <CustomInput
                label="Apto/Lote"
                errors={errors?.domicilio?.apto_lote}
                field={field}
                width={'100%'}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="domicilio.localidad"
            control={control}
            render={({ field }) => (
              <CustomInput
                label="Ciudad"
                errors={errors?.domicilio?.localidad}
                field={field}
                width="100%"
                isValid={touchedFields?.domicilio?.localidad}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl variant="filled" sx={{ width: '100%' }}>
            <InputLabel sx={{ color: '#FFF !important' }}>Provincia</InputLabel>
            <Controller
              name='domicilio.provincia'
              control={control}
              render={({ field }) => (
                <SelectInput
                  defaultValue=''
                  label='Provincia'
                  field={field}
                  data={provincias}
                  errors={errors?.domicilio?.provincia}
                  isValid={touchedFields?.domicilio?.provincia}
                />
              )
              }
            />
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddressData;
