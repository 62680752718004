import { useConnectionStatus, useNetworkMismatch, useSDK, useSwitchChain } from '@thirdweb-dev/react';
import React, { useContext, useEffect, useState } from 'react';
import ButtonBase from '../../../../../components/Buttons/ButtonBase';
import { OrderContext } from '../../../../../providers/orderContext';
import { ChainContext } from '../../../../../providers/chainContext';
import { AlertContext } from '../../../../../providers/alertContext';
import { TOKENS } from '../../../../../utils/constants/Chains';

const findToken = (token) => {
  const tx = TOKENS?.find(t => t.blockchain.toLowerCase() === token.toLowerCase());
  return tx;
};

const EvmConnectWallet = () => {
  const [loading, setLoading] = useState(false);
  const { paymentMethod, setPaymentMethod } = useContext(OrderContext);
  const { activeChain } = useContext(ChainContext);
  const { setAlert } = useContext(AlertContext);

  const sdk = useSDK();
  const isMismatched = useNetworkMismatch();
  const connectionStatus = useConnectionStatus();
  const switchChain = useSwitchChain();

  const handlePress = async () => {
    console.log('handle press paymentMethod', paymentMethod);
    try {
      if (paymentMethod && paymentMethod.tx && paymentMethod.tx.price_details?.final_amount && paymentMethod.tx.address) {
        setLoading(true);
        const contract = await sdk.getContract(activeChain.contract_address);
        if (!contract) {
          setLoading(false);
        }
        contract.erc20.transfer(paymentMethod?.tx?.address, paymentMethod.tx.price_details?.final_amount).catch(e => {
          if (e.reason === 'ERC20: transfer amount exceeds balance') {
            setAlert({ message: 'No hay fondos suficientes para realizar la transacción', severity: 'error' });
          }
          setLoading(false);
        });
      }
    } catch {
      setAlert({ message: 'Error en el pago, por favor intente mas tarde.', severity: 'error' });
      setLoading(false);
    }
  };

  const switchChainConnect = async () => {
    await switchChain(activeChain.chainId);
    // const item = findToken(activeChain.slug);
    // console.log('item,', item);
    // setPaymentMethod({ ...paymentMethod, type: 'crypto', options: { token: item } });
  };

  useEffect(() => {
    if (connectionStatus === 'connected' && isMismatched) {
      switchChainConnect();
    }
  }, [paymentMethod, isMismatched, connectionStatus]);

  return (
    <>

      {
        connectionStatus === 'disconnected' && (
          <ButtonBase.ConnectWallet />
        )
      }

      {
        connectionStatus === 'connected' && (
          <ButtonBase.Feedback onClick={handlePress} variant={'contained'} title={'Pagar'} loading={loading} />
        )
      }

      {
        connectionStatus === 'connecting' && (
          <ButtonBase.Feedback onClick={null} variant={'contained'} titleLoading={'Conectando...'} loading={true} />
        )
      }

    </>
  );
};

export default EvmConnectWallet;