import React, { useContext } from 'react';
//UI Components
import { Box, Divider, Grid, IconButton, Link } from '@mui/material';
//Icons
import InfoIcon from '@mui/icons-material/Info';
import { OrderContext } from '../../../providers/orderContext';

const About = ({ handleOpen, setValue }) => {

  const { checkout: { order } } = useContext(OrderContext);

  return (
    <Box
      xs={2}
      sm={7}
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
      }}
    >
      <Box sx={{ display: 'flex', '& hr': { mx: 2 } }}>
        <Link
          color='#fff'
          underline="hover"
          style={{
            cursor: 'pointer',
            fontSize: 10,
          }}
          variant='body2'
          download
          href={order.tyc ? order.tyc : order.merchant.default_tyc}
          target='_blank'
        >
          Términos & condiciones
        </Link>
        <Divider orientation="vertical" flexItem style={{ borderColor: 'white', opacity: 0.1 }} />
        <Link
          onClick={() => {
            setValue(0);
            handleOpen();
          }}
          color="#fff"
          underline="hover"
          variant="body2"
          style={{ cursor: 'pointer', fontSize: 10 }}
        >
          Privacidad
        </Link>
        <Divider orientation="vertical" flexItem style={{ borderColor: 'white', opacity: 0.1 }} />
        <Link
          onClick={() => {
            setValue(1);
            handleOpen();
          }}
          color="#fff"
          underline="hover"
          variant="body2"
          style={{ cursor: 'pointer', fontSize: 10 }}
        >
          Contacto
        </Link>
      </Box>
      {/* <IconButton
        sx={{ color: 'white', display: { xs: 'flex', md: 'none' } }}
        onClick={() => handleOpen()}
      >
        <InfoIcon />
      </IconButton> */}
    </Box>
  );
};

export default About;
