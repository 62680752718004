import React, { useContext } from 'react';
//Lottie
import Lottie from 'lottie-react';
import * as animationData from './assets/notfound.json';
//UI Components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
//React Router
import { useNavigate } from 'react-router-dom';
//Media queries
import useMediaQuery from '@mui/material/useMediaQuery';
//Theming and styling
import { useTheme } from '@mui/material/styles';
import { OrderContext } from '../../providers/orderContext';
import ButtonBase from '../../components/Buttons/ButtonBase';

const NotFound = () => {
  let navigate = useNavigate();
  const theme = useTheme();
  const { checkoutError } = useContext(OrderContext);
  const isDesktop = useMediaQuery(theme.breakpoints.up('md')); //Hook para identificar mediaqueries\\
  return (
    <>
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Lottie
          animationData={animationData}
          loop={false}
          style={{
            height: isDesktop ? 400 : 200,
            width: isDesktop ? 400 : 200
          }}
        />
        <Typography sx={{ color: '#FFF', fontSize: 33, fontWeight: 600 }}>
          Uppsss...
        </Typography>
        <Typography sx={{ color: '#fff', fontSize: 24, marginBottom: 2, fontWeight: 600 }}>
          {checkoutError.plan ? checkoutError.plan : 'Esta pagina no existe'}
        </Typography>

        <div>
          <ButtonBase variant="contained" onClick={() => navigate(-1)}>
            Volver
          </ButtonBase>
        </div>
      </Box>
    </>
  );
};

export default NotFound;
