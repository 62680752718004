import React, { useState } from 'react';
//UI Components
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, SwipeableDrawer, useTheme } from '@mui/material';
//Custom Components
import { Footer, OrderHeader } from '../../../components';
import DynamicElements from './DynamicElements/DynamicElements';
import WalletConnectHeader from '../../../components/OrderHeader/WalletConnectHeader/WalletConnectHeader';
import { Global } from '@emotion/react';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const Order = ({ isDesktop, initialData }) => {
  const theme = useTheme();

  return (
    <Container
      sx={{
        paddingLeft: 0,
        paddingRight: 0,
        backgroundColor: theme.palette.primary.main,
        height: '100%',
        paddingTop: { xs: 0, md: 2, xl: 9 }
      }}
    >
      <Box
        sx={{
          maxWidth: 630,
          display: 'flex',
          flexDirection: 'column',
          ml: { lg: 'auto' },
          mr: { xs: 0, xl: 4 },
          height: '100%',
          px: { xs: 0, md: 3 },
          justifyItems: 'center',
        }}
      >
        {/* <OrderHeader
          isDrawer={open}
          toggleDrawer={toggleDrawer(true)}
          isDesktop={isDesktop}
        /> */}

        <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
          <WalletConnectHeader />
        </Box>

        {isDesktop && (
          <Typography
            variant="h1"
            px={0}
            gutterBottom
            fontWeight={'900'}
            fontSize={'30px'}
            sx={{ color: theme.palette.common.white, width: { xs: 230, xl: '100%' }, lineHeight: '33px', marginBottom: '18px' }}
          >
            <span style={{ color: theme.palette.secondary.main }}>RESUMEN</span> DE LA ORDEN
          </Typography>
        )}

        {!isDesktop ? (
          <MobileDrawer />
        ) : (
          <DynamicElements data={initialData} isDesktop={isDesktop} />
        )}
        {
          isDesktop && <Footer />
        }
      </Box>
    </Container >
  );
};

const MobileDrawer = ({ initialData, isDesktop }) => {
  const [open, setOpen] = useState(false);
  const drawerBleeding = 85;

  return (
    <Box>
      <OrderHeader
        open={open}
        setOpen={setOpen}
        isDesktop={isDesktop}
      />
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            minHeight: `calc(310px + ${drawerBleeding}px)`,
            overflow: 'visible',
          },
        }}
      />

      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={true}
        allowSwipeInChildren={true}
      >
        <Box
          sx={{
            position: 'absolute',
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            right: 0,
            left: 0,
          }}
        >
          <Box sx={{
            width: 30,
            height: 6,
            backgroundColor: '#FFF',
            borderRadius: 3,
            position: 'absolute',
            top: 0,
            left: 'calc(50% - 25px)',
          }} />
          <ButtonDetails open={open} setOpen={setOpen} />
        </Box>
        <Box
          sx={{
            px: 2,
            pb: 5,
            height: '100%',
            backgroundColor: '#202128'
          }}
        >
          <DynamicElements
            data={initialData}
            isDrawer={open}
            isDesktop={isDesktop}
          />
        </Box>
      </SwipeableDrawer>
      {/* <Drawer anchor="bottom" open={open} ModalProps={{ onBackdropClick: toggleDrawer(false), keepMounted: true }} variant='persistent' >
        <Box
          sx={{ width: 'auto' }}
          role="presentation"
        >
          <DynamicElements
            data={initialData}
            isDrawer={open}
            isDesktop={isDesktop}
          />
        </Box>
      </Drawer> */}
    </Box>
  );
};

const ButtonDetails = ({ open, setOpen }) => {
  return (
    <Button
      variant="filled"
      fullWidth
      onClick={() => setOpen(!open)}
      sx={{
        color: 'white',
        backgroundColor: '#202128',
        padding: '24px',
        borderTopLeftRadius: '33px',
        borderTopRightRadius: '33px',
        '&:hover': {
          backgroundColor: '#202128'
        }
      }}
    >
      <Box sx={{ width: '100%', display: 'flex', gap: 2 }}>
        <AddCircleIcon
          sx={{ transform: open && 'rotate(180deg)', fontSize: '35px', color: '#FFD300' }}
        />
        <Typography sx={{ fontSize: '25px', fontWeight: 900, color: '#FFF' }}>
          {open ? 'Cerrar' : 'Detalles'}
        </Typography>
      </Box>
    </Button>
  );
};

export default Order;
