
export const TESTCHAIN = {
  chainId: 418,
  rpc: ['https://rpc.testnet.lachain.network'],
  nativeCurrency: {
    decimals: 18,
    name: 'LaCoin',
    symbol: 'TLA',
  },
  shortName: 'LATestnet',
  slug: 'latestnet',
  testnet: true,
  chain: 'lachain',
  name: 'LaChain',
  contract_address: '0xF8C5C07037e93714aa64b34241171d0b04f14a2d'
};

export const USDT_TEST = {
  'chainId': 137,
  'name': 'Polygon Mainnet',
  'shortName': 'Polygon',
  'slug': 'polygon',
  'nativeCurrency': {
    'name': 'MATIC',
    'symbol': 'MATIC',
    'decimals': 18
  },
  'rpc': ['https://go.getblock.io/d899bf2097b94aa8ae491057a512cc95'],
  'contract_address': '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
  'coin': 'USDT',
};

export const LACHAIN = {
  chainId: 274,
  rpc: ['https://rpc1.mainnet.lachain.network'],
  nativeCurrency: {
    decimals: 18,
    name: 'LaCoin',
    symbol: 'LAC',
  },
  shortName: 'LaChain',
  slug: 'lachain',
  chain: 'lachain',
  name: 'LaChain',
  contract_address: '0xDe09E74d4888Bc4e65F589e8c13Bce9F71DdF4c7',
  coin: 'uxd'
};

export const RSKTEST = {
  chainId: 31,
  rpc: ['https://public-node.testnet.rsk.co'],
  nativeCurrency: {
    decimals: 18,
    name: 'RBTC',
    symbol: 'RBTC',
  },
  shortName: 'RBTC',
  slug: 'rskchain',
  chain: 'rootstock',
  name: 'rsk test',
  contract_address: '0xCb46C0DdC60d18eFEB0e586c17AF6Ea36452DaE0',
  coin: 'doc'
};

const RSK = {
  chainId: 30,
  rpc: ['https://mycrypto.rsk.co/'],
  nativeCurrency: {
    decimals: 18,
    name: 'RSK',
    symbol: 'rbtc',
  },
  shortName: 'RSK',
  slug: 'rsk',
  chain: 'rootstock',
  name: 'rsk',
  contract_address: '0xe700691dA7b9851F2F35f8b8182c69c53CcaD9Db',
  coin: 'doc'
};

const POLYGON = {
  chainId: 137,
  name: 'Polygon Mainnet',
  shortName: 'Polygon',
  slug: 'polygon',
  nativeCurrency: {
    name: 'MATIC',
    symbol: 'MATIC',
    decimals: 18
  },
  rpc: ['https://go.getblock.io/d899bf2097b94aa8ae491057a512cc95'],
  contract_address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
  coin: 'USDT',
};

export const CHAINS = {
  'DOC': RSK,
  'UXD': LACHAIN,
  'USDT': POLYGON
};

export const SUPPORTED_CHAIN = [LACHAIN, RSK];

export const ACTIVE_CHAIN = CHAINS['UXD'];

export const TOKENS = [
  { 
    id: 1,
    name: 'Bitcoin', 
    coin: 'BTC',
    isEVM: false,
    blockchain: 'Lightning Network',
    logo: require('./assets/logo_btc.png'), 
    blockchain_logo: require('./assets/logo_ln.png'), 
    color: '#E8860F',
    address: '0xAHDghj1i27sdhik18sdhjaoqhdkjhdyq2'
  },
  { 
    id: 3,
    name: 'UXD',
    isEVM: true, 
    coin: 'UXD',
    blockchain: 'LaChain',
    logo: require('./assets/logo_uxd.png'), 
    blockchain_logo: require('./assets/logo_lachain.png'),
    color: '#0ab582'
  },
  // { 
  //   id: 4,
  //   name: 'BCH',
  //   coin: 'BCH',
  //   isEVM: false, 
  //   blockchain: null, 
  //   logo: require('./assets/logo_bch.png'), 
  //   blockchain_logo: null, 
  //   color: '#078b66'
  // },
  { 
    id: 5,
    name: 'DoC',
    coin: 'DOC',
    isEVM: true,
    blockchain: 'Rootstock',
    logo: require('./assets/logo_doc.png'), 
    blockchain_logo: require('./assets/logo_rootstock.png'),
    color: '#1c814c' 
  },
  { 
    id: 2,
    name: 'USDT',
    coin: 'USDT',
    isEVM: true, 
    blockchain: 'Polygon',
    logo: require('./assets/logo_usdt.png'),
    blockchain_logo: require('./assets/logo_polygon.png'),
    color: '#26a17b' 
  },  
];