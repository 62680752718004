import React from 'react';
//UI Components
import { Box, Grid } from '@mui/material';

const Maker = () => {
  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} width={'100%'} mb={1}>
      <img
        src={require('../assets/muvinai.png')}
        style={{ maxHeight: 30 }}
        alt="muvinai"
      />
      <img
        src={require('../assets/mercado.png')}
        style={{ maxHeight: 20, marginLeft: 15, filter: 'grayscale(100%)' }}
        alt="muvinai"
      />
    </Box>
  );
};

export default Maker;
