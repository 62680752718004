import { Box, Typography } from '@mui/material';
import React from 'react';
import InfoIcon from '@mui/icons-material/Info';

const InformationTooltip = ({ children }) => {
  return (
    <Box
      sx={{
        padding: '9px',
        backgroundColor: '#2F3036',
        borderRadius: '9px',
        display: 'flex',
        alignItems: 'flex-start',
        gap: '12px'
      }}>
      <InfoIcon sx={{ color: '#ADA4A4', fontSize: '27px' }} />
      {children}
    </Box>
  );
};

export default InformationTooltip;