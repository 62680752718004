import React, { useContext, useState } from 'react';
//UI Components
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
//Custom Components
import { FormSteps, PersonalData, PaymentData } from '.';
//React Hook-Forms
import { FormProvider, useForm } from 'react-hook-form';
//Yup - schema validation library
import { yupResolver } from '@hookform/resolvers/yup';
import { schemas, startValues } from './schema';
//React Router
import AddressData from './AddressData/AddressData';
import { useEffect } from 'react';
import { OrderContext } from '../../../providers/orderContext';
import { useLocation, useNavigate } from 'react-router-dom';
//Constants
import { config } from '../../../utils/constants/Constants';
import { Grid } from '@mui/material';
import Text from '../../../components/Text/Text';
import ButtonBase from '../../../components/Buttons/ButtonBase';
import TermsAndConditions from '../Order/DynamicElements/TermsAndConditions/TermsAndConditions';
import WalletConnectHeader from '../../../components/OrderHeader/WalletConnectHeader/WalletConnectHeader';
import { AlertContext } from '../../../providers/alertContext';

const steps = ['Paso 1', 'Paso 2', 'Success'];

const Form = ({ isDesktop, queryStep, planId }) => {
  const [activeStep, setActiveStep] = useState(parseInt(queryStep) - 1);
  const { checkout, setCheckout, step, setStep, checkoutError, setCheckoutError, setStatus } = useContext(OrderContext);
  const { setAlert } = useContext(AlertContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const {
    control,
    setValue,
    trigger,
    clearErrors,
    formState: { errors, touchedFields, isValid, isValidating },
    getValues,
    handleSubmit
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schemas[activeStep - 1]),
    defaultValues: startValues,
  });
  const [label, setLabel] = useState('Documento');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  //Construyo el objeto del cliente que le voy a pasar al back

  const formValues = getValues();

  useEffect(() => {
    setCheckout({ ...checkout, form: formValues });
  }, [isValid, isValidating]);

  useEffect(() => {
    const keys = Object.keys(errors);
    if (keys.length === 1 && activeStep === 1) {
      setOpen(true);
      setCheckoutError({ form: errors[keys[0]].message });
    } else if (keys.length > 1 && activeStep === 1) {
      setOpen(true);
      setCheckoutError({ form: 'Por favor completar todos los campos obligatorios del formulario' });
    }
  }, [errors]);

  // Kevin selector documento START
  useEffect(() => {
    if (formValues?.documento_tipo?.toLowerCase() === 'dni') {
      setLabel('dni');
    } else if (formValues?.documento_tipo?.toLowerCase() === 'pasaporte') {
      setLabel('pasaporte');
    }
    else {
      setLabel('dni');
    }
  }, [formValues]);
  // Kevin selector documento END
  // KEVIN : FACTURA A:
  const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1);

  // Por alguna razon este useEffect no esta haciendo setValue a menos que en el componente PaymentType ,
  // haga lo mismo pero sin un setValue.
  useEffect(() => {
    if (formValues?.factura_a === false) {
      setValue('cuit', '');
      clearErrors('cuit');
    }
  }, [formValues?.factura_a]);

  const planPath = getLastItem(location.pathname);

  const postClient = async (data) => {
    fetch(config.url.API_URL + '/order/' + planPath + location.search, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify(data)
    }).then(async response => {
      const data = await response.json();
      if (response.ok) {
        if (response.status === 201) {
          setStatus('success');
          navigate('/response/success', { replace: true });
          setLoading(false);
        } else if (response.status === 200) {
          if (activeStep < steps.length) {
            const isStepValid = await trigger();
            if (isStepValid) {
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
            setLoading(false);
          }
        }
      } else {
        const error = (data && data.msg) || response.status;
        console.log('errorrrr', error);
        setOpen(true);
        setCheckoutError({ ...checkoutError, form: error });
        setLoading(false);
        return Promise.reject(error);
      }
    })
      .catch(error => {
        console.log(error);
      });
  };

  const handleNext = async (e) => {
    setLoading(true);
    setCheckout({ ...checkout, form: formValues });
    if (isValid) {
      const data = { ...checkout, form: formValues };
      postClient(data);
    } else {
      setLoading(false);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setStep(step - 1);
  };

  useEffect(() => {
    let search = window.location.search;
    window.history.pushState(`/paso${activeStep - 1}/${planId}${search}`, 'New Page', `/paso${activeStep + 1}/${planId}${search}`);
    setStep(step + 1);
    window.scrollTo(0, 0);
  }, [activeStep]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', ml: { xs: 0, xl: 4 }, mb: { xs: 0 }, px: { md: 0 } }}>
      <Box sx={{ display: { xs: 'block', md: 'none' }, marginTop: '9px' }}>
        <WalletConnectHeader />
      </Box>
      <FormSteps activeStep={activeStep} isDesktop={isDesktop} />

      <Box sx={{ maxWidth: 700 }}>
        <FormProvider control={control} trigger={trigger} formState={{ errors, touchedFields, isValid, isValidating }} getValues={getValues}>
          <form
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
            action='/'
            method='POST'
            id="form-checkout"
            onSubmit={handleSubmit(handleNext)}
          >

            {
              activeStep === 1 && (
                <Step2
                  control={control}
                  errors={errors}
                  touchedFields={touchedFields}
                  onPressButton={handleNext}
                  disabled={!isValid} //checkout.order.final_price === 0 ???????
                  loading={loading}
                  order={checkout?.order}
                  labelDocument={label}
                />
              )
            }

            {
              activeStep === 2 && (
                <PaymentData
                  control={control}
                  errors={errors}
                  touchedFields={touchedFields}
                  setLoading={setLoading}
                  loading={loading}
                  handleBack={handleBack}
                />
              )
            }

          </form>
        </FormProvider>

        {checkoutError.form && (
          <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose}>
              {checkoutError.form}
            </Alert>
          </Snackbar>
        )
        }
      </Box>
    </Box>
  );
};

const Step2 = ({ control, errors, touchedFields, onPressButton, disabled, loading, order, labelDocument }) => {

  return (
    <Box sx={{ px: { xs: 2, md: 0 } }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 3, my: { xs: 0, xl: 3 } }}>
        <Text.Title>PASO 2</Text.Title>
        <Text.Subtitle fontSize={24} fontWeight={'300'}>Llená tus datos</Text.Subtitle>
      </Box>
      <Box display={'flex'} flexDirection={'column'}>
        <PersonalData
          control={control}
          errors={errors}
          touchedFields={touchedFields}
          labelDocument={labelDocument}
        />
        <AddressData
          control={control}
          errors={errors}
          touchedFields={touchedFields}
        />
      </Box>

      <Grid container alignItems={'center'} justifyContent={'space-between'} marginY={'15px'}>
        <Grid item xs={7} md={8}>
          <TermsAndConditions
            order={order}
            control={control}
            errors={errors}
          />
        </Grid>

        <Grid item xs={4} md={3}>
          <ButtonBase.Feedback loading={loading} title={'Continuar'} type={'submit'} />
        </Grid>
      </Grid>

    </Box>
  );
};

export default Form;