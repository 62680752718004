import React from 'react';
//Custom Components
import { Box, Card, Divider, Typography } from '@mui/material';
import { useContext } from 'react';
import { OrderContext } from '../../../../../providers/orderContext';

const CardElement = ({ children }) => {
  return (
    <Card
      variant="outlined"
      sx={{ border: '1px solid #5A5A5A', bgcolor: '#D9D9D90D', px: { xs: '16px', xl: '21px' }, py: '16px', borderRadius: '14px' }}
    >
      {children}
    </Card>
  );
};

const Order = ({ price, payment }) => {
  const { checkout: { order } } = useContext(OrderContext);

  return (
    <CardElement>
      <OrderElement
        price={price}
        img={order?.brand?.images?.image_light}
        title={order.name}
        subtitle={order.descripcion}
      />
      {(payment && payment.type === 'crypto' && payment.options?.token) && (
        <>
          <Divider />
          <Crypto payment={payment} />
        </>
      )}
    </CardElement>
  );
};

const Crypto = ({ payment }) => {
  return (
    <Box display={'flex'} alignItems={'center'} sx={{ paddingTop: 2, gap: 1 }}>
      <img
        src={payment.options?.token?.logo}
        alt="order"
        style={{ width: 30, height: 30, objectFit: 'cover' }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', flex: 1, alignItems: 'center' }}>
        <Typography sx={{ fontWeight: '600', fontSize: 16, color: '#FFF' }}>{payment.options?.token?.name}</Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Typography sx={{ fontWeight: '600', color: '#FFF', fontSize: 14 }} variant='subtitle1'>1 {payment?.tx?.coin}  =</Typography>
          <Typography sx={{ fontWeight: '500', color: '#FFF', fontSize: 14 }} variant='subtitle1'> ${parseInt(payment?.tx?.price_details.rate).toLocaleString('es-AR')} ARS</Typography>
        </Box>
      </Box>
    </Box>
  );
};

const OrderElement = ({ price, img, title, subtitle }) => {

  return (
    <Box display={'flex'} alignItems={'center'} style={{ marginBottom: { md: '9px' } }}>
      <img
        src={img}
        alt="order"
        style={{ width: 80, height: 80, objectFit: 'cover', borderRadius: '14px' }}
      />
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: { xs: 'flex-start', xl: 'center' },
          flexDirection: { xs: 'column', xl: 'row' },
          justifyContent: 'space-between',
          gap: { xs: 0, xl: 2 }
        }}
        pl={2}
      >
        <Box sx={{ flex: 5 }}>
          <Typography fontWeight={'700'} variant='h6' color={'#fff'}>{title}</Typography>
          <Typography fontWeight={'400'} variant='subtitle2' color={'#fff'}>{subtitle}</Typography>
        </Box>
        <Typography sx={{ color: '#FFF', fontSize: 21, fontWeight: 600 }}>{price}</Typography>
      </Box>
    </Box>
  );
};

CardElement.Order = Order;
CardElement.Crypto = Crypto;

export default CardElement;
