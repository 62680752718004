import React, { useContext, useEffect, useState } from 'react';
//UI Components
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
//Custom Component
import { Order, Form } from '.';
//Media queries
import useMediaQuery from '@mui/material/useMediaQuery';
//Theming and styling
import { useTheme } from '@mui/material/styles';
//Routing
import { useLocation, useParams, Navigate } from 'react-router-dom';
import { NotFound } from '..';
import { OrderContext } from '../../providers/orderContext';
import { config } from '../../utils/constants/Constants';
import { Footer } from '../../components';

const Purchase = ({ initialData }) => {
  const theme = useTheme();
  let { number, planId } = useParams();
  const [loading, setLoading] = useState(false);
  const { checkout, setCheckout, step, setCheckoutError, checkoutError } = useContext(OrderContext);
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg')); //Hook para identificar mediaqueries
  const location = useLocation();

  const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1);

  const planPath = getLastItem(location.pathname);
  let headers = {
    'Content-Type': 'application/json; charset=utf-8',
    'Authorization': 'uWRMahrBqIyFGzp226Ehpr2THTJ8ia'
  };

  const getRequest = {
    method: 'GET',
    headers: headers,
  };

  window.dataLayer.push({
    'namePlan': checkout.order.name + ' ' + (checkout?.order?.corporativo?.name ?? ''),
    'pricingPlan': checkout.order.final_price
  });


  const fetchPlan = () => {
    setLoading(true);
    fetch(config.url.API_URL + '/plan/' + planPath + location.search, getRequest)
      .then(async response => {
        const data = await response.json();
        if (!response.ok) {
          const error = (data && data.msg) || response.statusText;
          setLoading(false);
          return Promise.reject(error);
        }
        setCheckout({ ...checkout, order: data });
        setLoading(false);
        setCheckoutError({ ...checkoutError, plan: false });
      })
      .catch(e => {
        setCheckoutError({ ...checkoutError, plan: e });
        console.log('Fetching error', e);
      });
  };

  useEffect(() => {
    fetchPlan();
  }, []);

  if (!loading) {
    if (checkout.order && (number <= step)) {
      return (
        <Grid container sx={{ minHeight: '100vh' }} >
          <Grid container item xs={12} lg={8} justifyContent={'center'}>
            <Form isDesktop={isDesktop} queryStep={number} planId={planId} />
          </Grid>
          <Grid container item xs={12} lg={4}>
            <Order isDesktop={isDesktop} initialData={initialData} />
          </Grid>
          {!isDesktop && <Footer isDesktop={false} />}
        </Grid>
      );
    } else if (checkout.order && (number >= step)) {
      return <Navigate replace to={`/paso2/${planPath}`} />;
    } else {
      return <NotFound />;
    }
  } else {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }
};

export default Purchase;
