import { createTheme } from "@mui/material";
import brandTheme from "../theme";

//Theme especifico para la seccion de la orden

const darkTheme = createTheme(brandTheme, {
  palette: {
    secondary: {
      main: "#FAEEE7",
    },
    text: {
      primary: "#ffffff",
    },
    background: {
      default: "#395b64",
    }
  },
});

export default darkTheme;
