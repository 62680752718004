import React, { useContext } from 'react';
//UI Components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
//Custom components
import { OrderContext } from '../../providers/orderContext';
import { useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import sc_logo from '../../favicons/sportclub_logo.png';
import ButtonBase from '../../components/Buttons/ButtonBase';
import { Footer } from '../../components';
import CardElement from '../Purchase/Order/DynamicElements/OrderElements/OrderElements';
import { numberWithCommas } from '../../utils/helpers';

const Response = () => {
  const theme = useTheme();
  const { checkout: { form, order }, status, reset } = useContext(OrderContext);
  const navigate = useNavigate();

  window.dataLayer.push({
    'userMail': form.email,
    'responseStatus': status,
  });

  const handleBack = () => {
    reset();
    navigate(`/paso2/${order.slug}`);
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        width: '100%',
        height: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          flex: 10
        }}
      >
        {
          status !== 'error' ? (
            <Success handleBack={handleBack} form={form} status={status} />
          ) : (
            <Error handleBack={handleBack} />
          )
        }

      </Box>


      <Box sx={{ display: 'flex', alignSelf: 'flex-end', width: '100%', flex: 1, justifyContent: 'center' }}>
        <Footer />
      </Box>

    </Box>
  );
};

export default Response;

const Success = ({ handleBack, form, status }) => {
  const { checkout: { order }, paymentMethod } = useContext(OrderContext);

  let price = `$ ${numberWithCommas(order.price)}`;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '21px'
      }}
    >
      {
        console.log('statusss', status)
      }
      <img src={sc_logo} style={{ width: 212, height: 48 }} />
      <Box>
        <Typography
          style={{
            fontSize: '36px',
            fontWeight: '900',
            color: '#FFD300',
            textAlign: 'center',
            lineHeight: '60px'
          }}
        >
          GRACIAS {form?.nombre?.toUpperCase()}
        </Typography>
        {
          status === 'success' ? (
            <Typography
              style={{
                fontSize: '36px',
                fontWeight: '900',
                color: '#FFF',
                textAlign: 'center',
                lineHeight: '60px'
              }}
            >
              ¡BIENVENIDO  A SPORTCLUB!
            </Typography>
          ) : (status === 'in_process') && (
            <Box sx={{ maxWidth: '470px' }}>

              <Typography
                style={{
                  fontSize: '21px',
                  fontWeight: '700',
                  color: '#FFF',
                  textAlign: 'center',
                  lineHeight: '27px'
                }}
              >
                Estamos procesando tu pago, una vez que este sea debitado se te enviara un mail y podras acceder a la sede.
              </Typography>
            </Box>
          )
        }
      </Box>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', marginTop: '16px', paddingX: { xs: '12px', md: 0 } }}>
        <CardElement.Order price={price} />
        {
          (paymentMethod.type === 'crypto' && paymentMethod.options?.token) && (
            <Box sx={{ padding: '0 18px' }}>
              <CardElement.Crypto payment={paymentMethod} />
            </Box>
          )
        }
        {/* <Box sx={{ marginTop: '27px' }}>
          <ButtonBase
            variant={'contained'}
            style={{ padding: '12px 33px' }}
            onClick={handleBack}
          >
            Volver al inicio
          </ButtonBase>
        </Box> */}
      </Box>
    </Box>
  );
};

const Error = ({ handleBack }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '600px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '21px'
      }}
    >
      <img src={sc_logo} style={{ width: 212, height: 48 }} />
      <Typography
        style={{
          fontSize: '54px',
          fontWeight: '800',
          color: '#FFF',
          textAlign: 'center',
          lineHeight: '60px'
        }}
      >
        OCURRIÓ UN ERROR INESPERADO
      </Typography>
      <Box>
        <ButtonBase
          variant={'contained'}
          style={{ padding: '12px 33px' }}
          onClick={handleBack}
        >
          Volver
        </ButtonBase>
      </Box>
    </Box>
  );
};

{/* <ResponseHeaders />
{
  status !== 'error' ? (
    <>
      <Typography variant="h4" align="center">
        Gracias {form.nombre}
      </Typography>
      <Box sx={{ px: 3, py: 2 }}>
        <Typography align="center">Compraste el {order['name']} </Typography>
        <Typography align="center">
          {status === 'success' && 'En breve recibirás un email con el resumen de tu compra'}
          {status === 'in_process' && 'Estamos procesando tu pago, una vez que este sea debitado se te enviara un mail y podras acceder a la sede'}
        </Typography>
      </Box>
    </>
  ) : (
    <>
      <Typography variant="h4" align="center">
        Ocurrió un error inesperado
      </Typography>
      <Box sx={{ px: 3, py: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Button onClick={() => navigate(`/paso2/${order.slug}`)}>
          Volver
        </Button>
      </Box>
    </>
  )
} */}