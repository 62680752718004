import { DeleteOutline } from '@mui/icons-material';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Checkbox, FormControlLabel, FormGroup, Modal, Typography } from '@mui/material'
import React, { useState } from 'react'
import { currentCardDistribution, currentCardLogo } from '../../../../../utils/helpers';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    borderRadius: 3,
};

const CardModal = ({ open, setOpen, card, subscription, checked, setChecked, deleteCard, modifyCard }) => {
    const handleChange = (e) => {
        setChecked(e.target.checked)
    }

    const handleClose = () => setOpen(false)


    return (
        <Modal open={open}>
            <Box sx={[style, { width: { xs: 300, md: 600 } }]}>
                <Card sx={{ p: { xs: 2, md: 4 }, bgcolor: 'white' }}>
                    <CardHeader disableSpacing title='Información de la tarjeta' />
                    <CardContent sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignContent: 'flex-start' }}>
                        <Box sx={{ width: { xs: '100%', md: '50%' }, pr: 1 }}>
                            <Box sx={{ pb: 2 }}>
                                <Typography variant='h6'>Nombre en la tarjeta</Typography>
                                <Typography>{card.cardholder.name}</Typography>
                            </Box>
                            <Typography variant='h6'>Fecha de expiracion</Typography>
                            <Typography>{card.expiration_month}/{card.expiration_year - 2000}</Typography>
                        </Box>
                        <Box sx={{ width: { xs: '100%', md: '50%' } }}>
                            <Box sx={{ display: 'flex', alignContent: 'center', py: 2 }}>
                                {currentCardLogo(card.car_brand)}
                                {currentCardDistribution(card.card_brand, card.last_four_digits)}
                            </Box>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox
                                    checked={checked}
                                    onChange={handleChange}
                                />} label='Establecer como metodo de pago preferido' />
                            </FormGroup>

                        </Box>
                    </CardContent>
                    <CardActions disableSpacing sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                        <Button startIcon={<DeleteOutline />} color='error' onClick={deleteCard} sx={{ mb: { xs: 1, md: 0 } }}>
                            Eliminar
                        </Button>
                        <Box>
                            <Button variant='outlined' sx={{ mx: 1 }} onClick={handleClose}>
                                Cancelar
                            </Button>
                            <Button variant='contained' onClick={modifyCard}>
                                Guardar
                            </Button>
                        </Box>
                    </CardActions>
                </Card>
            </Box>
        </Modal>
    )
}

export default CardModal