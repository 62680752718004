import React from 'react';
//UI Components
import Typography from '@mui/material/Typography';
import { Box, Divider } from '@mui/material';

const Total = ({ title, variant, price }) => {


  return (
    <Box sx={{ margin: 'auto 0 10px 0' }}>
      <Divider flexItem style={{ borderColor: 'white', opacity: 0.1 }} />
      <Box display={'flex'} justifyContent={'space-between'} marginTop={1}>
        <Typography variant={'body1'} color={'#fff'} fontWeight={'700'}>{title}</Typography>
        <Typography variant={'body1'} color={'#fff'} fontWeight={'700'}>{price}</Typography>
      </Box>
    </Box>
  );
};

export default Total;
