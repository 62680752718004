import React from "react";
//UI Components
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';


const Disclaimer = ({ text, dark }) => {
  return (
    <Card
      variant="outlined"
      sx={{
        my: 3,
        bgcolor: dark ? "rgba(255, 255, 255, 0.05)" : "rgba(0, 0, 0, 0.05)",
        borderColor: dark ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.1)",
      }}
    >
      <CardContent>
        <Typography variant="body">
          {text}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Disclaimer;
