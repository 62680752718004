import  { useEffect, useRef } from 'react';
import api from '../../api';
import { useContext } from 'react';
import { OrderContext } from '../../providers/orderContext';
import { useNavigate } from 'react-router-dom';
import { AlertContext } from '../../providers/alertContext';

const STATUS_PAYMENT = {
  pending: 'pending',
  success: 'approved',
  abandoned: 'abandoned',
  error: 'error'
};

export const useFetchAttempts = () => {
  const { checkout: { order, form }, setAttemps, attemps } = useContext(OrderContext);

  const fetchAttemps = () => {
    if(attemps) return;

    api.post(`crypto_payment/${order.corporativo?._id}`, { 
      'documento': form.documento
    }).then(({data}) => {
      setAttemps(data.data);
    }).catch(e => {
      console.log('Error attempts');
    });
  };

  return fetchAttemps;

};

export const useRefetchPayment = () => {
  const { attemps, setStatus } = useContext(OrderContext);
  const refetchPayment = useRef(true);
  const navigate = useNavigate();
  const { setAlert } = useContext(AlertContext);
  const TIME_REFETCH = 2000;

  useEffect(() => {
    refetchPayment.current = true;

    if(attemps && attemps.id){
      refetch();
    }

    return () => refetchPayment.current = false;
  }, [attemps]);

  const refetch = () => {
    if (!refetchPayment.current) return;

    api.get(`crypto_payment/${attemps.id}`)
      .then(({ data }) => {
        const response = data?.data;
        if (response && response.payment_status.toLowerCase() === STATUS_PAYMENT.success) {
          setStatus('success');
          navigate('/response/success', { replace: true });
        } else if (response.payment_status.toLowerCase() === STATUS_PAYMENT.abandoned) {
          if (!refetchPayment.current) return;
          setTimeout(() => refetch(), TIME_REFETCH);
        } else if(response.payment_status.toLowerCase() === STATUS_PAYMENT.error) {
          setAlert({message: 'No se pudo completar la transacción', severity: 'error' });
        }
      }).catch((e) => {
        if(e?.response?.status !== 404){
          setAlert({message: 'OCURRIÓ UN ERROR INESPERADO', severity: 'error' });
        }
      });
  };
};