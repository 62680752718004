//Este archivo es para "helper functions"
import { Amex, CreditCard, Diners, Master, Visa } from './cards';

//Funcion que pone el separador de miles
export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export function stringAvatar(name,surename, color){
  const fullname = `${name} ${surename}`;
  return {
    sx: {
      bgcolor: color,
    },
    children: `${fullname.split(' ')[0][0]}${fullname.split(' ')[1][0]}`
  };
}

export function createSelectOptions(elem, options, labelsAndKeys = { label : 'name', value : 'id'}){
  const {label, value} = labelsAndKeys;

  elem.options.length = 0;

  const tempOptions = document.createDocumentFragment();

  options.forEach( option => {
    const optValue = option[value];
    const optLabel = option[label];

    const opt = document.createElement('option');
    opt.style.backgroundColor = '#2B2C33';
    opt.style.fontSize = '15px';
    opt.style.fontWeight = '600';

    opt.value = optValue;
    opt.textContent = optLabel;

    tempOptions.appendChild(opt);
  });

  elem.appendChild(tempOptions);
}

export function clearHTMLSelectChildrenFrom(element) {
  const currOptions = [...element.children];
  currOptions.forEach(child => child.remove());
}

export function currentCardLogo(payment_method) {
  if (payment_method !== undefined && payment_method.toLowerCase() === 'visa') {
    return <Visa />;
  } else if (payment_method !== undefined && payment_method.toLowerCase() === 'american express') {
    return <Amex />;
  } else if (payment_method !== undefined && payment_method.toLowerCase() === 'diners') {
    return <Diners />;
  } else if (payment_method !== undefined && payment_method.toLowerCase() === 'mastercard' || payment_method === 'debmaster') {
    return <Master />;
  } else {
    return <CreditCard />;
  }
}

export function currentCardDistribution(payment_method, last_digits){
  if (payment_method !== undefined && (payment_method.toLowerCase() === 'american express' || payment_method.toLowerCase() === 'diners')) {
    return `●●●● ●●●●●● ${last_digits}`;
  } else {
    return `●●●● ●●●● ●●●● ${last_digits}`;
  }
}

export function debounce(func, ms){
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, ms);
  };
};