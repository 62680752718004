import { Box, Tooltip, Typography } from '@mui/material';
import React from 'react';
import Text from '../Text/Text';


const Card = ({ children, selected, onPress, color = '#4D4E54', sx, tooltipProps, open }) => {
  const bg = 'linear-gradient(0deg, #5B5C66, #5B5C66), linear-gradient(0deg, rgba(217, 217, 217, 0.05), rgba(217, 217, 217, 0.05))';
  const bgSelected = `linear-gradient(0deg, ${color}, ${color}), linear-gradient(0deg, #FFFFFF, #FFFFFF)`;

  return (
    <Box
      onClick={onPress}
      sx={{
        cursor: onPress && 'pointer',
        boxShadow: selected && `0px 4px 27px 0px ${color}`,
        border: `1px solid ${selected ? '#FFF' : '#5B5C66'}`,
        borderRadius: '6px',
        padding: 1,
        background: selected ? bg : bgSelected,
        transition: 'all .75s',
        ...sx
      }}
    >
      {children}
    </Box>
  );
};

const CardToolTip = ({ children, selected, onPress, color = '#4D4E54', sx, tooltipProps, open }) => {

  return (
    <Tooltip
      open={open}
      {...tooltipProps}
    >
      <Box>
        <Card selected={selected} onPress={onPress} color={color} sx={sx}>
          {children}
        </Card>
      </Box>
    </Tooltip>
  );
};

const Payment = ({ selected, title, icon, onPress, discount }) => {
  return (
    <Card selected={selected} onPress={onPress} open={false}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: { xs: '148px', md: '172px' },
          justifyContent: 'center',
          alignItems: 'center',
          height: '120px',
          gap: '9px',
          filter: !selected && 'brightness(72%)'
        }}
      >

        <img src={icon} style={{ width: 48, height: 48 }} />
        <Text
          fontSize={'16px'}
          fontWeight={'600'}
          textAlign='center'
          color={'#FFF'}
        >
          {title}
        </Text>
        {
          discount && (
            <Box sx={{ backgroundColor: '#FFD300', padding: '3px 12px', borderRadius: '9px' }}>
              <Typography sx={{ color: '#2B2C33', fontSize: '12px', fontWeight: '700', textAlign: 'center' }}>
                {`Hasta ${discount} OFF`}
              </Typography>
            </Box>
          )
        }
      </Box>
    </Card>
  );
};

const Token = ({ selected, item, onPress, discount }) => {


  const onPressToken = () => {
    onPress(item);
  };

  return (
    <Card
      sx={{
        border: 'none',
        padding: '9px 12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 1,
        background: 'none',
        backgroundColor: selected ? item.color : '#5B5C66',
        filter: !selected && 'brightness(72%)',
        position: 'relative',
        width: { xs: '100%', md: 'auto' },
        marginTop: { xs: 2, md: 0 }
      }}
      onPress={onPressToken}
      selected={selected}
      color={item.color}
    >
      {
        discount && (
          <Box
            sx={{
              position: 'absolute',
              top: -18,
              right: 0,
              backgroundColor: selected ? item.color : '#5B5C66',
              height: '24px',
              borderRadius: '6px',
              borderBottomRightRadius: '0',
              transition: 'all .5s',
              padding: '3px 12px'
            }}
          >
            <Typography sx={{ color: '#FFF', fontSize: '12px', fontWeight: '700' }}>{discount}% OFF</Typography>
          </Box>
        )
      }
      <Box sx={{ display: 'flex', gap: { xs: 2, md: 1 } }}>
        <img src={item.logo} style={{ width: 30, height: 30 }} />
        <Typography variant='subtitle1' color={'#FFF'} fontWeight={'600'}>{item.name}</Typography>
      </Box>
      {
        item.blockchain && (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'nowrap',
              padding: '3px 9px',
              borderRadius: '18px',
              backgroundColor: selected && 'rgba(255,255,255,.3)',
              border: selected ? '1px solid transparent' : '1px solid rgba(255,255,255,.75)',
              alignItems: 'center',
              gap: 1,
              transition: 'all .5s'
            }}
          >
            <img src={item.blockchain_logo} style={{ width: 16, height: 16 }} />
            <Typography variant='subtitle1' fontSize={'12px'} fontWeight={'400'} color={'#FFF'}>{item.blockchain}</Typography>
          </Box>
        )
      }
    </Card>
  );
};

Card.Payment = Payment;
Card.Token = Token;

export default Card;