import React from 'react';
//UI Components
import Box from '@mui/material/Box';
//React Hook Form
import { Controller } from 'react-hook-form';
//Custom Components
import { PhoneInput, CustomInput } from '../../../../components';
import { DocumentInput, Nacimiento, SelectInput } from '../../../../components/Inputs/Inputs';
import Text from '../../../../components/Text/Text';
import { FormControl, Grid, InputLabel } from '@mui/material';
import { GENDERS } from '../../../../utils/constants/Constants';

const PersonalData = ({ control, errors, setActiveStep, touchedFields, labelDocument }) => {

  return (
    <Box sx={{ mb: { xs: 1, xl: 3 } }} noValidate autoComplete="off">
      <Text.Subtitle fontWeight={'400'} sx={{ mb: { xs: 1, xl: 2 } }}>Información personal</Text.Subtitle>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            name="nombre"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomInput
                width={'100%'}
                label="Nombre"
                errors={errors.nombre}
                field={field}
                isValid={touchedFields?.nombre && !errors.nombre && field.value !== ''}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="apellido"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomInput
                width={'100%'}
                label="Apellido"
                errors={errors.apellido}
                field={field}
                isValid={touchedFields?.apellido && !errors.apellido && field.value !== ''}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="celular"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <PhoneInput
                width={'100%'}
                errors={errors}
                field={field}
                touchedFields={touchedFields}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <FormControl variant="filled" sx={{ width: '100%' }}>
            <InputLabel style={{ color: '#FFF' }}>Tipo de Documento</InputLabel>
            <Controller
              name="documento_tipo"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <SelectInput
                  errors={errors?.documento_tipo}
                  label='Tipo de Documento'
                  field={field}
                  data={[
                    {
                      id: 1,
                      iso_nombre: 'DNI',
                      value: 'dni'
                    },
                    {
                      id: 2,
                      iso_nombre: 'Pasaporte',
                      value: 'pasaporte'
                    }
                  ]}
                  borders={{
                    topRight: 0,
                    topLeft: 0,
                    bottomLeft: 0,
                    bottomRight: 0,
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6} md={3}>
          <Controller
            name="documento"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <DocumentInput
                width={'100%'}
                errors={errors}
                field={field}
                touchedFields={touchedFields}
                labelDocument={labelDocument}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="email"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomInput
                width={'100%'}
                label="Email"
                id="form-checkout__cardholderEmail"
                errors={errors.email}
                field={field}
                tooltip="Utilizaremos tu email para que te valides en la app y para enviarte el comprobante de la compra"
                isValid={touchedFields?.email}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <FormControl variant="filled" sx={{ width: '100%' }}>
            <InputLabel sx={{ color: '#FFF !important' }}>Genero</InputLabel>
            <Controller
              name="genero"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <SelectInput
                  fullWidth
                  label='Genero'
                  field={field}
                  data={GENDERS}
                  errors={errors?.genero}
                  isValid={touchedFields?.genero}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} md={3}>
          <Controller
            name="nacimiento"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Nacimiento
                width={'100%'}
                label="Fecha de Nacimiento"
                id="form-checkout__nacimiento"
                errors={errors.nacimiento}
                field={field}
                isValid={touchedFields?.nacimiento}
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PersonalData;
