import { Box, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useAddress } from '@thirdweb-dev/react';
import metamaskIcon from '../../../favicons/metamask_icon.svg';
import { AlertContext } from '../../../providers/alertContext';

const WalletConnectHeader = () => {
  const address = useAddress();
  const { alert, setAlert } = useContext(AlertContext);

  const handleClick = () => {
    navigator.clipboard.writeText(address);
    setAlert({ ...alert, severity: 'success', message: 'Address copiado al portapapeles' });
  };

  if (address) {
    return (
      <Box
        sx={{
          backgroundColor: 'rgba(19, 20, 24, 1)',
          border: '1px solid #5A5A5A',
          width: '100%',
          borderRadius: '12px',
          padding: '9px',
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          cursor: 'pointer',
          transition: 'all .2s',
          marginBottom: { xs: 0, md: '33px' },
          '&:hover': {
            backgroundColor: 'rgba(19, 20, 24, .4)'
          }
        }}
        onClick={handleClick}
      >
        <img src={metamaskIcon} style={{ width: '27px', height: '27px' }} />
        <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', }}>
          {/* <Typography
            style={{
              color: '#FFF',
              fontSize: '14px'
            }}
          >
            Metamask
          </Typography> */}
          <Box sx={{
            direction: 'rtl',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            display: 'flex',
            gap: '3px'
          }}>
            <Typography
              style={{
                overflow: 'hidden',
                color: '#FFF',
                fontWeight: '600',
                fontSize: '14px'
              }}
            >
              {address}
            </Typography>
            <Typography
              style={{
                color: '#FFF',
                fontWeight: '600',
              }}
            >
              ...
            </Typography>

          </Box>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box sx={{ display: { xs: 'none', md: 'block' }, height: '52px', marginBottom: '33px' }}>

      </Box>
    );
  }

};

export default WalletConnectHeader;