import { Alert, Snackbar } from "@mui/material";
import React from "react";

const ErrorSnackBar = ({ open, message, status }) => {
  return (
    <Snackbar open={open} sx={{ maxWidth: 500 }}>
      <Alert severity={status} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default ErrorSnackBar;
