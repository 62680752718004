import * as Yup from 'yup';

export const discountSchema = Yup.object().shape({
  discount: Yup.string()
    .min(3, 'Debe tener más de 3 carácteres')
    .required('Este campo esta vacio'),
});

export const discountValues = {
  discount: '',
};
