import React, { useContext } from 'react';
import { AlertContext } from '../../providers/alertContext';
import { Box, Modal, Typography } from '@mui/material';
import ButtonBase from '../Buttons/ButtonBase';
import { useFetchAttempts } from '../../utils/hooks/useCrypto';

const ModalAfk = () => {
  const { modalAfk, setModalAfk } = useContext(AlertContext);
  const fetchAttemps = useFetchAttempts();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#292A31',
    border: '2px solid #4D4D4D',
    borderRadius: '25px',
    padding: '25px 42px',
    width: '500px'
  };

  const handleRefresh = () => {
    setModalAfk(false);
    fetchAttemps();
  };

  return (
    <Modal
      open={modalAfk}
    // onClose={handleClose}
    >
      <Box
        sx={style}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <Typography sx={{ fontSize: '24px', fontWeight: '600', color: '#FFF' }}>
            Actualización Requerida
          </Typography>
          <Typography sx={{ fontSize: '18px', color: '#FFF' }}>
            Su sesión requiere actualización para continuar. Por favor, actualice las cotizaciones.
          </Typography>

          <Box sx={{ width: '200px' }}>
            <ButtonBase variant={'contained'} onClick={handleRefresh}>
              Actualizar cotizaciones
            </ButtonBase>
          </Box>

        </Box>
      </Box>
    </Modal>
  );
};

export default ModalAfk;