import React, { createContext, useContext, useState } from 'react'

export const authContext = createContext()


export function AuthProvider({children}){
    let local_authed = localStorage.getItem('authed')
    let local_token = localStorage.getItem('token')
    const user = localStorage.getItem('user')
    if (user === '') {
      local_authed = 'false'
    }
    const isAuthed = local_authed === 'true' && user !== null
    if (!isAuthed) {
      localStorage.clear()
    }
    const [authed, setAuthed] = useState(isAuthed)
    const [token, setToken] = useState(local_token)

    return <authContext.Provider value={{
        authed,
        setAuthed,
        token,
        setToken
   } }>{children}</authContext.Provider>
}

export default function AuthConsumer() {
    return useContext(authContext)
}