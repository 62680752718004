import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import useStyles from '../../../../../components/Inputs/inputStyles.js';
import { FormLabel, InputLabel } from '@mui/material';


let cantidad = [];

for (var i = 1; i <= 12; i++) {
  cantidad.push(i);
}

export default function Installments({ errors, id, name, label, control, installments }) {
  const classes = useStyles();

  return (
    <>
      <FormControl
        variant="filled"
        sx={{ width: '100%' }}
      >
        <Select
          variant="filled"
          id={id}
          native={true}
          className={classes.selectInput}
          sx={{
            borderRadius: '6px !important',
          }}
          inputProps={{
            sx: {
              borderRadius: '6px !important',
              padding: '12px !important',
              border: errors.installments ? '1px solid rgba(255, 0, 0, 0.5)' : '1px solid #5B5C66',
            },
          }}
          disabled={installments ? false : true}

        ></Select>

      </FormControl>
    </>
  );
}
