import React, {useState, createContext } from 'react';
import { ACTIVE_CHAIN } from '../utils/constants/Chains';

export const ChainContext = createContext();

export const ChainProvider = ({children}) => {
  const [activeChain, setChain] = useState(ACTIVE_CHAIN);
  
  const setActiveChain = (chain) => {
    if(chain){
      setChain(chain);
    }
  };

  return(
    <ChainContext.Provider
      value={{
        activeChain,
        setActiveChain
      }}
    >
      {children}
    </ChainContext.Provider>
  );
};