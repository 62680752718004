import * as Yup from 'yup';
import moment from 'moment';

const LettersSpaces = /^[a-zA-ZùÙüÜäàáëèéïìíöòóüùúÄÀÁËÈÉÏÌÍÖÒÓÜÚñÑ\s]*$/;
const LettersSpacesNumbers = /^[a-zA-Z0-9ùÙüÜäàáëèéïìíöòóüùúÄÀÁËÈÉÏÌÍÖÒÓÜÚñÑ\s]*$/;

const currentYear = moment().format('YY');

const validYear = currentYear - 1;


const step1 = Yup.object({
  nombre: Yup.string()
    .required('El campo nombre es requerido')
    .matches(
      LettersSpaces,
      'El nombre solo puede contener letras y espacios'
    ),
  apellido: Yup.string()
    .required('El campo apellido es requerido')
    .matches(
      LettersSpaces,
      'El apellido solo puede contener letras y espacios'
    ),
  celular: Yup.string()
    .required('El campo celular es requerido')
    .min(10, 'El celular debe tener 10 caractéres')
    .max(10, 'El celular debe tener 10 caractéres'),
  email: Yup.string()
    .email('El email ingresado no es valido')
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9]+)*$/,
      'No debe contener caracteres especiales'
    )
    .test('ml', 'No podes utilizar tu email corporativo para subscribirte', value => !value.includes('mercadolibre'))
    .required('El campo email es requerido'),
  documento_tipo:Yup.string()
    .required('El campo documento es requerido'),
  documento:Yup.string()
    .required('El campo documento es requerido')
    .min(7, 'El documento debe tener más de 6 caractéres'),
  // .max(8, "El documento debe menos de 9 caractéres")
  // .matches(/^[0-9]*$/, 'El documento sólo puede contener números'),
  acceptTerms: Yup.bool()
    .required()
    .oneOf([true], 'Por favor acepta los términos y condiciones antes de continuar'),
  genero: Yup.string()
    .required('Debes ingresar un genero'),
  nacimiento: Yup.string()
    .required('Debes ingresar la fecha de nacimiento'),
  domicilio: Yup.object().shape({
    calle: Yup.string()
      .required('Debes ingresar una calle')
      .matches(
        LettersSpacesNumbers,
        'Solo puede contener números y letras'
      ),
    altura: Yup.string()
      .required('Debes ingresar tu número de dirección')
      .matches(/^[0-9]*$/, 'Este campo solo debe contener números')
      .max(10, 'No puede tener más de 10 cáracteres'),
    apto_lote: Yup.string(),
    localidad: Yup.string().required('Debes ingresar una ciudad'),
    provincia: Yup.string()
      .required('Debes ingresar una provincia')
      .matches(/^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/, 'No debe contener números ni carácteres especiales'),
  })
});

const step2 =  Yup.object({
  payment: Yup.object().shape({
    identificationType: Yup.array().of(Yup.number().min(1)).nullable(),
    identificationNumber: Yup.string()
      .required('Este campo es requerido')
      .matches(/^[0-9]*$/, 'El documento solo debe contener números')
      .min(6, 'El documento debe tener más de 7 caractéres')
      .max(10, 'El documento debe menos de 10 caractéres'),
    cardHolderName: Yup.string()
      .required('El nombre del titular es requerido')
      .matches(
        LettersSpaces,
        'El nombre solo puede contener letras y espacios'
      ),
    cardNumber: Yup.string()
      .required('Debes ingresar una tarjeta')
      .matches(/^[0-9]*$/, 'La tarjeta solo debe tener números')
      .min(14, 'Tu tarjeta debe tener por lo menos 14 dígitos')
      .max(18, 'Tu tarjeta no debe tener más de 18 dígitos'),
    cardExpirationMonth: Yup.string()
      .required('Este campo es requerido')
      .test('len', 'El campo debe tener 2 numeros', val => val.length === 2)
      .test('len', 'El mes ingresado no es válido', val => Number(val) < 13 && Number(val) > 0),
    cardExpirationYear: Yup.lazy((value) => {
      if (value === '') {
        return Yup.string()
          .required('Este campo es requerido');
      }
      return Yup.number()
        .required('Este campo es requerido')
        .moreThan(validYear, 'El año ingresado es invalido');
    }),
    securityCode: Yup.string()
      .required('Este campo es requerido')
      .min(3, 'Tu CVV tiene que tener al menos 3 numeros')
      .max(4, 'Tu CVV no puede ser mayor a 4'),

    cuit: Yup.string()
      .matches(/^(?!3).+$/, 'El cuit ingresado no corresponde con un responsable inscripto')
      .required('Este campo es requerido'),
    // .matches(/^[0-9]*$/, 'El documento solo debe contener números')
    // .min(6, "El documento debe tener más de 7 caractéres")
    // .max(12, "El documento debe menos de 12 caractéres"),
    acceptTerms: Yup.bool()
      .required()
      .oneOf([true], 'Por favor acepta los términos y condiciones antes de continuar'),
  }),
  cuit: Yup.string()
    .matches(/^(?!3).+$/, 'El cuit ingresado no corresponde con un responsable inscripto')
    .required('Este campo es requerido'),
  // .matches(/^[0-9]*$/, 'El documento solo debe contener números')
  // .min(6, "El documento debe tener más de 7 caractéres")
  // .max(12, "El documento debe menos de 12 caractéres"),
});


export const startValues = {
  nombre: '',
  apellido: '',
  celular: '',
  email: '',
  documento: '',
  documento_tipo: 'dni',
  factura_a:false,
  cuit:'',
  acceptTerms: false,
  tarjeta: true, //este parametro me dice si es tarjeta o transferencia y condicionalmente valida
  domicilio: {
    calle: '',
    altura: '',
    apto_lote: '',
    localidad: '',
    provincia: '',
  },
  payment: {
    identificationType: {
      'id': 'DNI',
      'name': 'DNI',
      'type': 'number',
      'min_length': 7,
      'max_length': 8
    },
    identificationNumber: '',
    cardHolderName: '',
    cardNumber: '',
    cardExpirationMonth: '',
    cardExpirationYear: '',
    securityCode: '',
    installments: '',
    issuer: {},
  }
};

export const schemas = [step1, step2];