import { Box, Button, CircularProgress, Grid, IconButton, InputAdornment, Modal, Skeleton, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import Card from '../../../../../components/Card/Card';
import { OrderContext } from '../../../../../providers/orderContext';
import Text from '../../../../../components/Text/Text';
import { CustomInput } from '../../../../../components';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EvmConnectWallet from '../Flow/EvmConnectWallet';
import { CHAINS, TOKENS } from '../../../../../utils/constants/Chains';
import { useFetchAttempts, useRefetchPayment } from '../../../../../utils/hooks/useCrypto';
import { AlertContext } from '../../../../../providers/alertContext';
import QRCodeComponent from '../../../../../components/QRCode/QRCodeComponent';
import { ChainContext } from '../../../../../providers/chainContext';
import CountdownPayment from '../../../../../components/CountdownPayment/CountdownPayment';
import InformationTooltip from '../../../../../components/InformationTooltip/InformationTooltip';
import { useNavigate } from 'react-router-dom';

const findTransaction = (token, transactions) => {
  const tx = transactions?.find(t => t.coin.toLowerCase() === token.coin.toLowerCase());
  return tx;
};

const hasDiscount = (token, transactions) => {
  const tx = findTransaction(token, transactions);
  return tx?.price_details?.discount_rate;
};

const PaymentCrypto = ({ control, errors }) => {
  const { paymentMethod, setPaymentMethod, checkout: { order }, attemps } = useContext(OrderContext);
  const { setActiveChain } = useContext(ChainContext);
  const fetchAttempt = useFetchAttempts();

  const isEVM = paymentMethod?.options?.token?.isEVM;


  const onPressCardToken = (item) => {
    const tx = findTransaction(item, attemps.transactions);
    const chain = CHAINS[tx.coin];
    setPaymentMethod({ type: 'crypto', options: { token: item }, tx });
    setActiveChain(chain);
  };

  useEffect(() => {
    fetchAttempt();
  }, []);

  return (
    <Box sx={{ width: { xs: 'auto', md: '700px' } }}>
      <Text.Subtitle sx={{ color: '#DADADA', px: { xs: 2, md: 0 } }}>Selecciona tu moneda</Text.Subtitle>
      <Box
        sx={{
          display: 'flex',
          rowGap: 3,
          columnGap: 2,
          flexWrap: 'wrap',
          overflowX: { xs: 'scroll', md: 'visible' },
          width: { xs: '100%', md: 'auto' },
          pt: 3,
          pb: 4,
          px: { xs: 2, md: 0 },
          '::-webkit-scrollbar': {
            display: 'none'
          },
        }}
      >
        {
          attemps ? (
            TOKENS.map(item => {
              const selected = paymentMethod.options?.token?.id === item.id;
              return (
                <Card.Token
                  item={item}
                  selected={selected}
                  onPress={() => onPressCardToken(item)}
                  discount={hasDiscount(item, attemps.transactions)}
                />
              );
            })
          ) : (
            TOKENS.map(() => <Skeleton variant="rounded" width={210} height={48} sx={{ borderRadius: 1 }} />)
          )
        }
      </Box>

      {
        paymentMethod?.options?.token && (
          <Payment attemps={attemps} isEVM={isEVM} order={order} control={control} errors={errors} />
        )
      }

      <Grid item xs={12} md={8}
        alignItems={'center'}
        justifyItems={'center'}
        sx={{ display: 'flex', alignItems: 'flex-end', marginTop: '12px' }}
      >
        {/* <Button sx={{ color: '#fff' }} variant='text' onClick={handleBack}>
          <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>Atrás</Typography>
        </Button> */}
      </Grid>

    </Box >
  );
};

const Payment = ({ isEVM, attemps }) => {

  useRefetchPayment();

  if (attemps) {
    return (
      <>
        <Box sx={{ flex: 1, display: 'flex', gap: '21px', alignItems: 'center', marginBottom: 2 }}>
          <Text.Subtitle sx={{ color: '#DADADA', px: { xs: 2, md: 0 } }}>Efectúa el pago</Text.Subtitle>
          <CountdownPayment expiration={attemps.expiration_time} />
        </Box>
        <Grid container spacing={2} alignItems={'center'} sx={{ px: { xs: 2, md: 0 } }}>
          {
            isEVM ? (
              <PaymentEVM />
            ) : (
              <InvoiceInput />
            )
          }
        </Grid>
      </>
    );
  }
};

const PaymentEVM = () => {
  const { paymentMethod } = useContext(OrderContext);
  const { alert, setAlert } = useContext(AlertContext);

  const onCopy = (msg, txt) => {
    if (txt) {
      navigator.clipboard.writeText(txt);
      setAlert({ ...alert, severity: 'success', message: `${msg} copiado al portapapeles` });
    }
  };

  return (
    <>
      <Grid item xs={12} md={6}>
        <CustomInput
          startAdornment={
            <IconButton
              aria-label="copy-address"
              onClick={() => onCopy('Address', paymentMethod?.tx?.address)}
              edge="end"
              sx={{ marginRight: '2px' }}
            >
              <ContentCopyIcon style={{ fontSize: 21 }} />
            </IconButton>
          }
          width={'100%'}
          label={'Address'}
          value={paymentMethod?.tx?.address}
          inputProps={{
            flex: 12,
            readOnly: true,
            shrink: true,
            style: {
              direction: 'rtl',
              unicodeBidi: 'bidi-override',
              textAlign: 'left',
            }
          }}
          inputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomInput
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="copy-amount"
                onClick={() => onCopy('Monto', paymentMethod?.tx?.price_details.final_amount)}
                edge="end"
              >
                <ContentCopyIcon style={{ fontSize: 21 }} />
              </IconButton>
            </InputAdornment>
          }
          width={'100%'}
          label={'Monto'}
          value={paymentMethod?.tx?.price_details.final_amount}
          inputProps={{ readOnly: true }}
          inputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <InformationTooltip>
          <Typography sx={{ fontSize: '12px', color: '#ADA4A4', fontWeight: '500' }}>
            Para pagar copiá y pegá la dirección de pago y el monto en tu wallet, o conecta la wallet a través de Wallet Connect.
            Asegurate de seleccionar la blockchain correcta e introducir el monto exacto.
          </Typography>
        </InformationTooltip>
      </Grid>
      <Grid item xs={12} md={4}>
        <EvmConnectWallet />
      </Grid>
    </>
  );
};

const InvoiceInput = () => {
  const { paymentMethod, setStatus } = useContext(OrderContext);
  const { alert, setAlert } = useContext(AlertContext);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  const onCopy = () => {
    navigator.clipboard.writeText(paymentMethod.tx?.address);
    setAlert({ ...alert, severity: 'success', message: 'Invoice copiado al portapapeles' });
  };

  const handlePressQR = () => {
    setOpenModal(state => !state);
    // setStatus('success');
    // navigate('/response/success', { replace: true });
  };

  const handlePressPayLN = () => {
    window.open(`lightning:${paymentMethod.tx?.address}`, '_blank');
  };

  return (
    <>
      <Grid item xs={12} md={9}>
        <CustomInput
          startAdornment={
            <IconButton
              aria-label="copy-invoice"
              onClick={onCopy}
              edge="end"
              sx={{ marginRight: '2px' }}
            >
              <ContentCopyIcon style={{ fontSize: 21 }} />
            </IconButton>
          }
          width={'100%'}
          label={'Invoice'}
          value={paymentMethod.tx?.address}
          inputProps={{
            flex: 12,
            readOnly: true,
            shrink: true,
            style: {
              direction: 'rtl',
              unicodeBidi: 'bidi-override',
              textAlign: 'left',
            }
          }}
          inputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={2} md={3} display={{ xs: 'none', md: 'block' }}>
        <Button variant={'contained'}
          style={{ backgroundColor: '#FFD300', color: '#000', width: '100%', display: 'flex', justifyContent: 'space-around', padding: '12px 16px' }}
          onClick={handlePressQR}
        >
          <Typography sx={{ fontWeight: '800', textTransform: 'none' }}>Mostrar QR</Typography>
          {/* <QrCodeIcon /> */}
        </Button>
      </Grid>
      <ModalQRCode open={openModal} setOpen={setOpenModal} data={paymentMethod.tx?.address} />
      <Grid item xs={12}>
        <InformationTooltip>
          <Typography sx={{ fontSize: '12px', color: '#ADA4A4', fontWeight: '500' }}>
            Copiá el código de la invoice y pegalo en tu wallet de Bitcoin con Lightning Network.
            Tenes 30 minutos después de iniciado el proceso de compra para finalizar tu compra
          </Typography>
        </InformationTooltip>
      </Grid>
      <Grid item xs={12} display={{ xs: 'block', md: 'none' }}>
        <Button variant={'contained'}
          style={{ backgroundColor: '#FFD300', color: '#000', width: '100%', display: 'flex', justifyContent: 'space-around', padding: '12px 16px' }}
          onClick={handlePressPayLN}
        >
          <Typography sx={{ fontWeight: '800' }}>PAGAR</Typography>
          {/* <QrCodeIcon /> */}
        </Button>
      </Grid>
    </>
  );
};

const ModalQRCode = ({ open, setOpen, data }) => {
  const { paymentMethod } = useContext(OrderContext);

  const tokenSelected = paymentMethod?.tx;
  const priceDetails = tokenSelected?.price_details;
  const priceDiscount = priceDetails?.discount_rate > 0 && (priceDetails.ars_amount - priceDetails.ars_final_amount);

  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#292A31',
    border: '2px solid #4D4D4D',
    borderRadius: '25px',
    padding: '25px 42px'
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      {
        data ? (
          <Box
            style={style}
          >
            <Box style={{ display: 'flex', flexDirection: 'column', marginBottom: '18px' }}>
              <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 1 }}>
                <Typography sx={{ color: '#FFF', fontWeight: '600' }}>Importe a abonar</Typography>
                <Typography sx={{ color: '#FFF', fontWeight: '600' }}>${priceDetails?.ars_final_amount}</Typography>
              </Box>
              {/* <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ color: '#FFF', fontWeight: '500', fontSize: '15px' }}>Descuento {priceDetails?.discount_rate}%</Typography>
                <Typography sx={{ color: '#FFF', fontWeight: '500', fontSize: '15px' }}> - {priceDiscount} ARS</Typography>
              </Box> */}
              <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ color: '#FFF', fontWeight: '500', fontSize: '15px' }}>Pagando con crypto</Typography>
                <Typography sx={{ color: '#FFF', fontWeight: '500', fontSize: '15px' }}>{paymentMethod?.tx.price_details.final_amount} BTC</Typography>
              </Box>
              <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ color: '#FFF', fontWeight: '500', fontSize: '15px' }}>Cambio</Typography>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Typography sx={{ color: '#FFF', fontWeight: '500', fontSize: '15px' }}>1 {paymentMethod?.tx?.coin} =</Typography>
                  <Typography sx={{ color: '#FFF', fontWeight: '500', fontSize: '15px' }}>${parseInt(paymentMethod?.tx?.price_details.rate).toLocaleString('es-AR')} ARS</Typography>
                </Box>
              </Box>
            </Box>
            <QRCodeComponent url={data} size={15} />
          </Box>
        ) : (
          <CircularProgress />
        )
      }
    </Modal>
  );
};



export default PaymentCrypto;