import React, { useContext, useEffect, useState } from 'react';
//UI Components
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
//Custom Components
import { Disclaimer, Discount, Total } from '.';
//utils
import { numberWithCommas } from '../../../../utils/helpers';
import ExtraFees from './ExtraFees/ExtraFees';
//Context
import { OrderContext } from '../../../../providers/orderContext';
import CardElement from './OrderElements/OrderElements';
import { Skeleton, Typography } from '@mui/material';

const DynamicElements = ({ isDrawer, isDesktop }) => {
  const { checkout: { order }, paymentMethod } = useContext(OrderContext);
  const tokenSelected = paymentMethod?.tx;
  const priceDetails = tokenSelected?.price_details;

  let price = `$ ${numberWithCommas(order.price)}`;
  let final_price = `$ ${numberWithCommas(order.final_price)}`;
  let debitoHaberes = order?.corporativo ? `$ ${numberWithCommas(order?.corporativo?.['descuento-haberes'])}` : 0;


  if (order?.corporativo?.['descuento-haberes'] > 0) {
    let priceHaberes;
    if (order.final_price === 0) {
      priceHaberes = 0;
    } else {
      priceHaberes = order.price - order?.corporativo?.['descuento-haberes'];
    }
    final_price = `$ ${numberWithCommas(priceHaberes)}`;
  }

  return (
    <Box
      sx={{
        m: { xs: '18px', md: 0 },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        gap: 3
      }}>

      <CardElement.Order
        price={price}
        payment={paymentMethod}
      />
      {/* {
        (paymentMethod.type === 'crypto' && paymentMethod.options?.token) && (
          <CardElement.Crypto payment={paymentMethod} />
        )
      } */}
      {
        order.discount &&
        <>
          <Divider
            style={{ borderColor: 'white', opacity: 0.2 }}
            variant="middle"
          />
          <Discount />
        </>
      }

      <Box style={{ marginTop: 'auto' }}>
        {
          order?.corporativo?.['descuento-haberes'] > 0 && (
            <>
              <ExtraFees name='Débito por haberes' item={debitoHaberes} />
              <Total title="Importe a abonar" variant="h6" price={final_price} />
            </>
          )
        }

        {
          paymentMethod.type === 'crypto' && (
            <CryptoAmount paymentMethod={paymentMethod} />
          )
        }

        {
          paymentMethod.type === 'crypto' && paymentMethod.tx ? (
            <Total title="Importe a abonar" variant="h6" price={`$ ${priceDetails?.ars_final_amount}`} />
          ) : paymentMethod.type !== 'crypto' && (order?.corporativo?.['descuento-haberes'] === 0 || order?.corporativo?.['descuento-haberes'] == null) && (
            <Total title="Importe a abonar" variant="h6" price={final_price} />
          )
        }

        {
          paymentMethod.type === 'crypto' && paymentMethod.tx && (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography sx={{ color: '#FFF', fontSize: '14px' }}>Pagando con Crypto</Typography>
              {
                tokenSelected ? (
                  <Typography sx={{ color: '#FFF', fontSize: '14px' }}>{tokenSelected.price_details.final_amount} {paymentMethod.options?.token.coin?.toUpperCase()}</Typography>
                ) : (
                  <Skeleton variant="rectangular" width={75} height={15} />
                )
              }
            </Box>
          )
        }

        {
          order?.corporativo?.['descuento-haberes'] > 0 && (
            <Disclaimer text="La suscripción a este plan no requiere tarjeta de crédito ya que el precio es abonado por la institución a la que perteneces, la que podrá debitar dicho importe de tus haberes de acuerdo a sus normas internas."
              dark={true}
            />
          )
        }

      </Box>
    </Box>
  );
};

const CryptoAmount = ({ paymentMethod }) => {
  const tokenSelected = paymentMethod?.tx;
  const priceDetails = tokenSelected?.price_details;
  const priceDiscount = priceDetails?.discount_rate > 0 && (priceDetails.ars_amount - priceDetails.ars_final_amount);

  // const [amount, setAmount] = useState(null);
  // const decimals = 8;

  // const calculate_amount = () => {
  //   if (tokenSelected?.coin === 'BTC') {
  //     const result = tokenSelected.amount / (10 ** decimals);
  //     setAmount(result);
  //   } else {
  //     setAmount(paymentMethod.tx.amount);
  //   }
  // };

  // useEffect(() => {
  //   if (tokenSelected) {
  //     calculate_amount();
  //   }
  // }, [paymentMethod]);

  if (tokenSelected) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {
          priceDetails?.discount_rate > 0 && (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography sx={{ color: '#FFF', fontSize: '14px' }}>Descuento {priceDetails?.discount_rate}%</Typography>
              {
                tokenSelected ? (
                  <Typography sx={{ color: '#FFF', fontSize: '14px' }}> - $ {priceDiscount}</Typography>
                ) : (
                  <Skeleton variant="rectangular" width={75} height={15} />
                )
              }
            </Box>
          )
        }
        {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography sx={{ color: '#FFF', fontSize: '14px' }}>Pagando con Crypto</Typography>
          {
            tokenSelected ? (
              <Typography sx={{ color: '#FFF', fontSize: '14px' }}>{tokenSelected.amount} {paymentMethod.options?.token.coin?.toUpperCase()}</Typography>
            ) : (
              <Skeleton variant="rectangular" width={75} height={15} />
            )
          }
        </Box> */}
      </Box>
    );
  }
};

export default DynamicElements;
