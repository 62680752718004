import './App.css';
import MainView from './pages/MainView';
import { darkTheme } from './theme';
import { ThemeProvider } from '@mui/material';
import { OrderProvider } from './providers/orderContext';
import { ThirdwebProvider } from '@thirdweb-dev/react';

import { UserProvider } from './providers/userContext';
import { AuthProvider } from './utils/hooks/useAuth';
import { SUPPORTED_CHAIN } from './utils/constants/Chains';
import { AlertProvider } from './providers/alertContext';
import { ChainContext, ChainProvider } from './providers/chainContext';
import { useContext, useEffect } from 'react';

function App() {

  return (
    <ChainProvider>
      <OrderProvider>
        <Main />
      </OrderProvider>
    </ChainProvider>
  );
}

const Main = () => {
  const { activeChain } = useContext(ChainContext);

  useEffect(() => {
    console.log('Active Chain', activeChain?.chain);
  }, [activeChain]);

  return (
    <ThirdwebProvider
      // supportedWallets={[
      //   metamaskWallet({ connectionMethod: 'metamaskBrowser' }),
      //   coinbaseWallet(),
      //   walletConnect(),
      // ]}
      activeChain={activeChain}
      clientId="1d2579d7c5e50567baa7e9c55742600a"
      supportedChains={SUPPORTED_CHAIN}
    >
      <ThemeProvider theme={darkTheme}>
        <AuthProvider>
          <UserProvider>
            <AlertProvider>
              <MainView />
            </AlertProvider>
          </UserProvider>
        </AuthProvider>
      </ThemeProvider>
    </ThirdwebProvider>
  );
};

export default App;
