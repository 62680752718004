export const initialData = {
  "titulo-plan": "Plan Local",
  name: "Plan Local Martinez",
  slug: "rochi",
  descripcion: "Descripcion bla bla bla",
  price: 4000,
  "price-compare-at": 4500,
  cobro: "Mensual",
  "costo-membresia": 9000,
  "texto-condicion": "bla bla bla",
  "logo-de-prestador": "link.com/logo",
  "imagen-del-plan": "link.com/imagen",
  "terminos-y-condiciones": "rich text largo..",
  "politica-de-privacidad": "rich text largo..",
  "acepta-cuotas": true,
  "nivel-de-acceso": "Local",
  "plan-publico": true,
  "es-corporativo": false,
  contacto: "contacto@sportclub.com.ar",
  "acepta-cupon": false,
};

//precio compare can be null
