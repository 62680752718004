import React, { useContext } from 'react';
//UI Components
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
//Custom Components
import Maker from './Maker/Maker';
import About from './About/About';
import ModalInfo from '../ModalInfo/ModalInfo';
import { OrderContext } from '../../providers/orderContext';

const Footer = ({ isDesktop = true }) => {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const { checkout: { order } } = useContext(OrderContext);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const width = !isDesktop ? '100%' : 'auto';

  return (
    <Box width={width} sx={{ paddingBottom: 2 }}>
      <Card elevation={0} sx={{ backgroundColor: 'transparent' }}>
        <CardContent sx={{ pb: 1, opacity: 0.9 }}>
          <Grid container direction="row" alignItems="center">
            <Maker />
            <About handleOpen={handleOpen} setValue={setValue} />
          </Grid>
          <Box sx={{ display: 'flex', marginTop: 1, alignItems: 'center' }}>
            {
              order?.brand?.name === 'SportClub' &&
              <>
                {/* <img src={require('./assets/afip.jpg')} alt='Afip qr' width={45} height={65} /> */}
                <Typography fontSize={9} color='#969696' align="center">
                  SportClub es una marca registrada. Gestión de Emprendimientos Deportivos S.A.,
                  CUIT 30-71028354-7, Paraguay 2060, Ciudad Autónoma de Buenos Aires es licenciataria de la marca SportClub.
                </Typography>
              </>
            }
          </Box>
        </CardContent>
      </Card>
      {/* <ModalInfo
        open={open}
        handleClose={handleClose}
        value={value}
        handleChange={handleChange}
      /> */}
    </Box>
  );
};

export default Footer;
