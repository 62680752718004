import { Checkbox, FormGroup, Link, Typography, useTheme } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

const TermsAndConditions = ({ errors, order, control }) => {
  const theme = useTheme();

  return (
    <FormGroup
      noValidate
      autoComplete="off"
      sx={{ flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'center', my: 1 }}
    >
      <Controller
        name="acceptTerms"
        control={control}
        render={({ field }) => (
          <Checkbox
            checked={field.value}
            {...field}
            style={{ color: errors.acceptTerms ? theme.palette.error.main : theme.palette.secondary.main }}
          />
        )}
      />
      <Typography sx={{ fontSize: 14, maxWidth: '100%', color: 'rgba(255,255,255,.7)' }}>
        Acepto los
        <Link color='inherit' underline="always"
          sx={{
            cursor: 'pointer',
            fontSize: 15,
            marginLeft: 0.5,
            fontWeight: 700
          }}
          download
          href={order.tyc}
          target='_blank'
        >
          términos y condiciones
        </Link> y la política de privacidad
      </Typography>
    </FormGroup>
  );
};

export default TermsAndConditions;