import React, {useState, createContext} from 'react';

export const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({message: null, severity: null, duration: 5000});
  const [modalAfk, setModalAfk] = useState(false);

  return(
    <AlertContext.Provider
      value={{
        alert,
        setAlert,
        modalAfk,
        setModalAfk
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};