import React, {useState, createContext} from 'react';

export const OrderContext = createContext();

export const OrderProvider = (props) => {
  const [paymentMethod, setPaymentMethod] = useState({type: '', options: null});
  const [attemps, setAttemps] = useState(null);
  const [checkout, setCheckout] = useState({ form: false, order: false});
  const [step, setStep] = useState(2);
  const [checkoutError, setCheckoutError] = useState({ discount: false, form: false, plan: false});
  const [status, setStatus] = useState(false);

  const reset = () => {
    setPaymentMethod({ type: '', options: null });
    setStatus(false);
    setAttemps(null);
    setStep(2);
    setCheckout({ form: false, order: false});
  };

  return(
    <OrderContext.Provider
      value={{
        checkout,
        setCheckout,
        paymentMethod,
        setPaymentMethod,
        attemps,
        setAttemps,
        step,
        setStep,
        checkoutError,
        setCheckoutError,
        status,
        setStatus,
        reset
      }}
    >
      {props.children}
    </OrderContext.Provider>
  );
};