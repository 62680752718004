import card_icon from '../../favicons/card_icon.png';
import crypto_icon from '../../favicons/crypto_icon.png';

export const COUNTDOWN_MINS = 30;

export const PAYMENT_METHODS = [
  {
    key: 'card',
    title: 'Tarjeta Crédito / Débito',
    icon: card_icon
  },
  {
    key: 'crypto',
    title: 'Crypto',
    icon: crypto_icon
  },
  // {
  //   key: 'mercadopago',
  //   title: 'Mercado Pago',
  //   icon: mercadopago_icon

  // }
];

export const CONTRACT_ADDRESS = '0xF8C5C07037e93714aa64b34241171d0b04f14a2d';
export const ADDRESS_BACKEND = '0x7D43949f1B9c7Ebc7A152A73B9839CBF01c7e222';


export const config = {
  url: {
    API_URL: process.env.REACT_APP_CHECKOUT_BACKEND_URL
  }
};

export const GENDERS = [
  {
    'id': 0,
    'nombre_completo': 'Masculino',
    'nombre': 'Masculino',
    'iso_nombre': 'Masculino',
  },
  {
    'id': 1,
    'nombre_completo': 'Femenino',
    'nombre': 'Femenino',
    'iso_nombre': 'Femenino',
  },
  {
    'id': 2,
    'nombre_completo': 'Otro',
    'nombre': 'Otro',
    'iso_nombre': 'Otro',
  },
  
];
