import React, { useContext } from "react";
//UI Components
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
//Icons
import TabPanel from "./TabPanel/TabPanel";
import { Divider, Typography } from "@mui/material";
import { OrderContext } from "../../providers/orderContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "95vw", md: "50vw" },
  bgcolor: "common.white",
  borderRadius: 1,
  boxShadow: 24,
  height: 'auto',
};

const ModalInfo = ({ open, handleClose, value, handleChange }) => {

  const { checkout: { order } } = useContext(OrderContext)

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h5" sx={{ p: 2 }}>
          Políticas
        </Typography>
        <Divider />
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="icon tabs example"
          >
            <Tab label="Privacidad" />
            <Tab label="Contacto" />
          </Tabs>
        </Box>
        <Box>
          <TabPanel value={value} index={0}>
            <Box sx={{ overflow: 'scroll', height: { xs: 300, md: 400 }, px: 1 }}>
              <div dangerouslySetInnerHTML={{ __html: order?.brand?.politica_privacidad }}>
              </div>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Box sx={{ height: { xs: 300, md: 400 }, px: 1 }}>
              <Typography>
                Contacto: {order.contacto}
              </Typography>
            </Box>
          </TabPanel>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalInfo;
