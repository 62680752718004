import { Alert, Snackbar } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { AlertContext } from '../../providers/alertContext';

const AlertSnackbar = () => {
  const alert = useContext(AlertContext);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if(alert?.alert.message && alert?.alert.severity){
      const duration = alert?.alert.duration ? alert.alert?.duration : 5000;
      setOpen(true);
      setTimeout(() => setOpen(false), duration);
    }
  }, [alert]);

  return (
    <Snackbar open={open} autoHideDuration={alert?.alert.duration}>
      {
        (alert.alert?.message && alert.alert?.severity) && (
          <Alert severity={alert.alert?.severity} >
            {alert.alert.message}
          </Alert>
        )
      }
    </Snackbar>
  );
};

export default AlertSnackbar;