import React from 'react';
//UI Components
import { Box, Button, Typography } from '@mui/material';
//Icons
import AddCircleIcon from '@mui/icons-material/AddCircle';

const OrderHeader = ({ open, setOpen, isDesktop }) => {
  const handleClick = () => {
    console.log('handleClick', open);
    setOpen(true);
  };
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        width: '100%',
        position: 'fixed',
        bottom: !open && 0,
        zIndex: 99,
        left: 0,
        justifyContent: 'space-between',
      }}
    >
      {/* <Box display="flex" alignItems="center">
        <Img
          style={{ maxHeight: 26 }}
          src={checkout?.order?.brand?.images?.horizontal_white}
          alt="brand logo"
          sx={{ maxHeight: 26, ml: isDrawer && 3 }}
        />
      </Box> */}
      {!isDesktop && (
        <Button
          variant="filled"
          onClick={handleClick}
          fullWidth
          sx={{
            color: 'white',
            backgroundColor: '#202128',
            padding: '24px',
            borderTopLeftRadius: '33px',
            borderTopRightRadius: '33px',
            '&:hover': {
              backgroundColor: '#202128'
            }
          }}
        >
          <Box sx={{ width: '100%', display: 'flex', gap: 2 }}>
            <AddCircleIcon
              sx={{ transform: open && 'rotate(180deg)', fontSize: '35px', color: '#FFD300' }}
            />
            <Typography sx={{ fontSize: '25px', fontWeight: 900, color: '#FFF' }}>
              {open ? 'Cerrar' : 'Detalles'}
            </Typography>
          </Box>
        </Button>
      )}
    </Box>
  );
};

export default OrderHeader;
