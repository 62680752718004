import { Box, Typography } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { AlertContext } from '../../providers/alertContext';
import { OrderContext } from '../../providers/orderContext';

const calculateTimeLeft = (targetDate) => {
  const now = dayjs();

  const minutes = Math.max(0, targetDate.diff(now, 'minutes') % 60).toString().padStart(2, '0');;
  const seconds = Math.max(0, targetDate.diff(now, 'seconds') % 60).toString().padStart(2, '0');;

  return { minutes, seconds };
};

const CountdownPayment = ({ expiration }) => {
  const { setModalAfk } = useContext(AlertContext);
  const { setAttemps } = useContext(OrderContext);
  const finalTime = dayjs(expiration);
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeLeft(finalTime));
  const ref = useRef();

  const updateTimer = () => {
    const remainingTime = calculateTimeLeft(finalTime);
    ref.current = requestAnimationFrame(updateTimer);

    if (remainingTime.minutes === '00' && remainingTime.seconds === '00') {
      cancelAnimationFrame(ref.current);
      setModalAfk(true);
      setAttemps(null);
    }
    setTimeRemaining(remainingTime);
  };

  useEffect(() => {
    const rafId = requestAnimationFrame(updateTimer);

    return () => {
      cancelAnimationFrame(rafId);
      cancelAnimationFrame(ref.current);
    };
  }, []);

  return (
    <Box sx={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
      <UnitTime unit={String(timeRemaining.minutes)[0]} />
      <UnitTime unit={String(timeRemaining.minutes)[1]} />
      <Typography sx={{ fontSize: '16px', fontWeight: '700', color: '#FFF' }}>:</Typography>
      <UnitTime unit={String(timeRemaining.seconds)[0]} />
      <UnitTime unit={String(timeRemaining.seconds)[1]} />
    </Box>
  );
};

const UnitTime = ({ unit }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        paddingY: '3px',
        width: '24px',
        backgroundColor: '#3F4046',
        borderRadius: '4px',
        height: 'fit-content'
      }}
    >
      <Typography sx={{ fontSize: '15px', fontWeight: '700', color: '#FFF' }}>{unit}</Typography>
    </Box>
  );
};

export default CountdownPayment;