import React, { useContext, useEffect } from 'react';
//UI Components
import Box from '@mui/material/Box';
//Custom Components
import { OrderContext } from '../../../../providers/orderContext';
import Text from '../../../../components/Text/Text';
import { PAYMENT_METHODS } from '../../../../utils/constants/Constants';
import Card from '../../../../components/Card/Card';
import PaymentCard from './PaymentCard/PaymentCard';
import PaymentCrypto from './PaymentCrypto/PaymentCrypto';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { IconButton } from '@mui/material';

const PaymentData = ({ control, errors, touchedFields, setLoading, loading, handleBack }) => {
  const { paymentMethod, setPaymentMethod, checkout: { order } } = useContext(OrderContext);
  const isPlanCrypto = order?.corporativo?.slug === 'promocion-plan-total-anual-ripio';

  const switchPayment = (method) => {
    setPaymentMethod({ type: method });
  };

  useEffect(() => {
    if ((order?.payment_methods?.crypto && paymentMethod.type === '') || isPlanCrypto) {
      setPaymentMethod(prev => ({ ...prev, type: 'crypto' }));
    } else if (order?.payment_methods?.tarjeta && paymentMethod.type === '') {
      setPaymentMethod(prev => ({ ...prev, type: 'card' }));
    }
  }, []);

  return (
    <Box sx={{ py: { xs: 0, xl: 2 }, alignSelf: 'flex-start', boxSizing: 'border-box' }}>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 1, px: { xs: 3, md: 0 } }}>
        <IconButton>
          <KeyboardBackspaceIcon sx={{ color: '#fff', fontSize: '24px' }} onClick={handleBack} />
        </IconButton>
        <Text.Title sx={{ marginRight: '12px', fontSize: { xs: '27px', md: '33px' } }}>PASO 3</Text.Title>
        <Text.Subtitle fontSize={21} fontWeight={'300'}>Método de Pago</Text.Subtitle>
      </Box>

      <PaymentsOptions paymentMethod={paymentMethod} onPress={switchPayment} order={order} isPlanCrypto={isPlanCrypto} />

      <Box sx={{ py: 1, maxWidth: '100vw' }}>
        {
          paymentMethod.type === 'card' && (
            <PaymentCard
              control={control}
              errors={errors}
              touchedFields={touchedFields}
              setLoading={setLoading}
              loading={loading}
              handleBack={handleBack}
            />
          )
        }

        {
          paymentMethod.type === 'crypto' && (
            <PaymentCrypto
              control={control}
              errors={errors}
              setLoading={setLoading}
              handleBack={handleBack}
            />
          )
        }

        {/* {
          paymentMethod.type === 'mercadopago' && (
            <PaymentMercadoPago />
          )
        } */}
      </Box>
    </Box>
  );
};

const PaymentsOptions = ({ paymentMethod, onPress, order, isPlanCrypto }) => {

  return (
    <Box
      sx={{
        overflowX: 'scroll',
        display: 'flex',
        justifyContent: 'flex-start',
        py: 4,
        maxWidth: { xs: '100vw', md: 'auto' },
        px: { xs: '18px', md: 0 },
        gap: { xs: 3, md: 5 },
        '::-webkit-scrollbar': {
          display: 'none'
        },
      }}
    >
      {
        (order?.payment_methods?.tarjeta && !isPlanCrypto) && (
          <Card.Payment
            selected={PAYMENT_METHODS[0].key === paymentMethod.type}
            icon={PAYMENT_METHODS[0].icon}
            title={PAYMENT_METHODS[0].title}
            onPress={() => onPress(PAYMENT_METHODS[0].key)}
          />
        )
      }

      {
        (order?.payment_methods?.crypto || isPlanCrypto) && (
          <Card.Payment
            selected={PAYMENT_METHODS[1].key === paymentMethod.type}
            icon={PAYMENT_METHODS[1].icon}
            title={PAYMENT_METHODS[1].title}
            onPress={() => onPress(PAYMENT_METHODS[1].key)}
            discount={'25%'}
          />
        )
      }
    </Box>
  );
};

export default PaymentData;
