import React, { createContext, useEffect, useState } from 'react'

export const UserContext = createContext()

export const UserProvider = (props) => {
    let local_user = localStorage.getItem('user')
    if (local_user !== '' && local_user !== null){
      local_user = JSON.parse(local_user)
    }
    const [user, setUser] = useState(local_user)
    const [userId, setUserId] = useState(JSON.parse(localStorage.getItem('userId')))
    
  return (
    <UserContext.Provider
    value={{
        user,
        setUser, 
        userId,
        setUserId,
    }}
    >
 {props.children}
    </UserContext.Provider>
  )
}
