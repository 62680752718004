import { useState, useEffect, useContext, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { OrderContext } from '../../providers/orderContext';
import { config } from '../constants/Constants';
import { clearHTMLSelectChildrenFrom, createSelectOptions } from '../helpers';
import useScript from './useScript';
import { AlertContext } from '../../providers/alertContext';

export default function useMercadoPago({ setCardImage, setInstallments, setLoading, cardNumberWatch }) {
  const [error, setError] = useState({ text: '', status: '' });
  const {checkout, setStatus, setCheckout, checkout: { order: { cobro }}} = useContext(OrderContext);
  const location = useLocation();
  const navigate = useNavigate();
  const {formState: {isValid,errors}} = useFormContext();
  const { alert, setAlert } = useContext(AlertContext);
  const cobro_dict = {
    'Mensual': 1,
    'Trimestral': 3,
    'Cuatrimestral': 3,
    'Semestral': 6,
    'Anual': 12
  };
  const url = process.env.REACT_APP_ENV === 'development' ? 'https://developers.decidir.com/api/v2' : 'https://live.decidir.com/api/v2';
  const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1);
  const planPath = getLastItem(location.pathname);

  const { MercadoPago } = useScript(
    'https://sdk.mercadopago.com/js/v2',
    'MercadoPago'
  );
  const baseUrl = 'https://api.mercadopago.com/v1';

  const public_key = checkout.order.merchant.public_key;

  const formRef = useRef(null);
  const cardNumberElement = useRef(null);
  const issuerElement = useRef(null);
  const paymentMethodElement = useRef(null);
  const installmentsElement = useRef(null);

  useEffect(() => {
    if (!formRef.current) {
      formRef.current = document.getElementById('form-checkout');
    }
    if (!cardNumberElement.current) {
      cardNumberElement.current = document.getElementById('form-checkout__cardNumber');
    }
    if (!issuerElement.current) {
      issuerElement.current = document.getElementById('form-checkout__issuer');
    }
    if (!paymentMethodElement.current) {
      paymentMethodElement.current = document.getElementById('paymentMethodId');
    }
    if (!installmentsElement.current) {
      installmentsElement.current = document.getElementById('form-checkout__installments');
    }
  }, []);

  const getIdentificationTypes = async () => {
    try {
      const res = await fetch(`${baseUrl}/identification_types?public_key=${public_key}`);
      const identificationTypes = await res.json();

      const identificationTypeElement = document.getElementById('form-checkout__identificationType');

      createSelectOptions(identificationTypeElement, identificationTypes);
    } catch (e) {
      return console.error('Error getting identificationTypes: ', e);
    }
  };

  const getInstallments = async () => {
    try{
      const cardNumber = cardNumberElement.current.value;

      const res = await fetch(`${baseUrl}/payment_methods/installments?public_key=${public_key}&bin=${cardNumber.slice(0,6)}&amount=${String(checkout.order.final_price)}`);
      const installments = await res.json();

      let final_installments = [{
        'installments': 1,
        'installment_rate': 0,
        'discount_rate': 0,
        'reimbursement_rate': null,
        'labels': [
          'CFT_0,00%|TEA_0,00%'
        ],
        'installment_rate_collector': [
          'MERCADOPAGO'
        ],
        'min_allowed_amount': 1,
        'max_allowed_amount': 1500000,
        'recommended_message': '1 cuota de $ 0,00 ($ 0,00)',
        'installment_amount': 0,
        'total_amount': 0,
        'payment_method_option_id': '1.AQokODllZjQyNjktYjAzMy00OWU1LWJhMWQtNDE0NjQyNTM3MzY4EJaFuevHLg'
      }];
      if (checkout.order.final_price > 0) {
        final_installments = installments[0].payer_costs;
      }
      createSelectOptions(installmentsElement.current, final_installments, {label: 'recommended_message', value: 'installments'});
      setInstallments(final_installments);

      let options = document.querySelectorAll('#form-checkout__installments > option');
      options.forEach((option) => {
        if (checkout.order['cuotas'] === 0) {
          const cuotas = cobro_dict[cobro];
          if (Number(option.value) === 2 || Number(option.value) === 9) {
            option.remove();
          } else if (Number(option.value) === cuotas){
            option.selected = 'selected';
          } else if (Number(option.value) > cuotas) {
            option.remove();
          }
        }
        
        if (checkout.order['cuotas']) {
          if (Number(option.value) === checkout.order['cuotas']){
            option.selected = 'selected';
          } else {
            option.remove();
          }
        }
      });

      const hasCuotas = installments[0].payer_costs.find(i => i.installments === checkout.order['cuotas']);

      if(checkout.order['cuotas'] !== 0 && !hasCuotas){
        console.log('tirar mensaje error');
        setAlert({ duration: 5000, severity: 'error', message: 'Tu tarjeta no admite el pago en cuotas que requiere este plan. Por favor intentá con otra tarjeta' });
      }

      console.log('options cuotas', cobro, checkout.order['cuotas'], installments[0].payer_costs, hasCuotas);

    } catch (e) {
      console.error('error getting installments: ', e);
    }
  };

  const getIssuers = async () => {
    try {
      const cardNumber = cardNumberElement.current.value;
      const paymentMethodId = paymentMethodElement.current.value;
      const res = await fetch(`${baseUrl}/payment_methods/card_issuers?public_key=${public_key}&bin=${cardNumber.substring(0,6)}&payment_method_id=${paymentMethodId}`);
      const issuers = await res.json();

      createSelectOptions(issuerElement.current, issuers);

      getInstallments();
    } catch (e) {
      console.error('error getting issuers: ', e);
    }
  };

  const paymentMethods = async (cardNumber) => {
    try {
      if (cardNumber.length < 6 && paymentMethodElement.current.value) {
        clearHTMLSelectChildrenFrom(issuerElement.current);
        clearHTMLSelectChildrenFrom(installmentsElement.current);
        paymentMethodElement.current.value = '';
        setError({text: '', status: ''});
        return;
      }

      if (cardNumber.length >= 6 && !paymentMethodElement.value){
        const bin = cardNumber.substring(0,6);
        const res = await fetch(`${baseUrl}/payment_methods/search?public_key=${public_key}&bins=${bin}&marketplace=NONE`);
        const paymentMethods = await res.json();

        const {id: paymentMethodId, additional_info_needed, issuer } = paymentMethods.results[0];

        paymentMethodElement.current.value = paymentMethodId;

        if(paymentMethods !== undefined || paymentMethods !== null){

          console.log('Payment Methods Available:', paymentMethods.results);

          setCheckout({
            ...checkout,
            payment: {
              ...checkout.payment,
              payment_data: {
                ...checkout.payment.payment_data,
                payment_method_id: paymentMethods.results[0].id,
                installments: Number('1')
              }
            }
          });
          setCardImage(paymentMethods.results[0].thumbnail);

          // if (checkout.order.cobro === 'Anual' && checkout.order["acepta-cuotas"]) {
          //   if (!paymentMethods.results[0].payer_costs[5].labels.find((element) => element === "ahora")) {
          //     setError({
          //       ...error,
          //       text: 'La tarjeta que ingresaste no está adherida al plan AHORA 12. En caso de seleccionar el pago en 12 cuotas se te cobrarán 12 cuotas pero estas tendrán interés.',
          //       status: 'warning'
          //     })
          //   } else {
          //     setError({text: '', status: ''})
          //   }
          // }

        }
        additional_info_needed.includes('issuer_id')
          ? getIssuers()
          : (() => {
            createSelectOptions(issuerElement.current, [issuer]);

            getInstallments();
          })();
      }
    } catch (e) {
      setCardImage(false);
      setError({
        ...error,
        text: 'Esa tarjeta no es soportada por nuestro procesador de pagos',
        status: 'error'
      });
      console.error('error getting payment methods: ', e);
    }
  };

  const mp = useRef(null);

  useEffect(() => {
    if(MercadoPago){
      mp.current = new MercadoPago(String(public_key));
      setCheckout({
        ...checkout,
        payment: {
          payment_method: 'mercadopago',
          payment_data: {
            transaction_amount: checkout.order.final_price
          }
        }
      });
      getIdentificationTypes();
    }
  }, [MercadoPago, public_key]);

  useEffect(() => {
    if(MercadoPago){
      mp.current = new MercadoPago(String(public_key));
      const cardNumberElement = document.getElementById('form-checkout__cardNumber');
      let cardNumber = cardNumberElement?.value;
      if(cardNumber !== '' && cardNumber !== undefined){
        paymentMethodElement.current.value = '';
        paymentMethods(cardNumber);
      }
    }
  }, [MercadoPago, cardNumberWatch]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.addEventListener('submit', createCardToken);
    }

    return () => {
      formRef.current.removeEventListener('submit', createCardToken);
    };
  }, [checkout]);

  const createCardToken = async (event) => {
    setLoading(true);
    if(Object.keys(errors).length === 0) {
      event.preventDefault();

      let token;
      try{
        token = await mp.current.createCardToken({
          cardNumber: checkout.form.payment.cardNumber,
          cardholderName: checkout.form.payment.cardHolderName,
          identificationType: 'DNI',
          identificationNumber: checkout.form.payment.identificationNumber,
          securityCode: checkout.form.payment.securityCode,
          cardExpirationMonth: checkout.form.payment.cardExpirationMonth,
          cardExpirationYear: checkout.form.payment.cardExpirationYear
        });
      } catch(e) {
        console.log(e);
        const error = e[0];
        const errorsDict = {
          'invalid parameter cardExpirationMonth': 'La tarjeta ingresada está vencida',
        };
        let snackError = { text: errorsDict[error.message], status: 'error' };
        setError(snackError);
        setLoading(false);
      }

      let token_decidir;
      try {
        const response = await fetch(`${url}/tokens`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'apikey': checkout.order.merchant.public_key_decidir
          },
          body: JSON.stringify({
            'card_number': checkout.form.payment.cardNumber,
            'card_expiration_month': checkout.form.payment.cardExpirationMonth,
            'card_expiration_year': checkout.form.payment.cardExpirationYear,
            'security_code': checkout.form.payment.securityCode,
            'card_holder_name': checkout.form.payment.cardHolderName,
            'card_holder_identification': {
              'type': 'dni',
              'number': checkout.form.payment.identificationNumber
            }
          })
        });
        const data = await response.json();
        token_decidir = data;
      } catch(e) {
        let snackError = { text: 'error', status: 'error' };
        setError(snackError);
        setLoading(false);
      }

      setCheckout({...checkout,  payment: {...checkout.payment, payment_data: {...checkout.payment.payment_data, token: token?.id}}});

      if (token){
        checkout.payment.payment_data = {
          ...checkout.payment.payment_data,
          token: token?.id,
          token_decidir: token_decidir.id ? token_decidir.id : '',
          installments: installmentsElement.current.value,
          first_six_digits: token?.first_six_digits
        };
        checkout.form = {
          'apellido': checkout.form.apellido,
          'celular': checkout.form.celular,
          'documento': checkout.form.documento,
          'documento_tipo': checkout.form.documento_tipo,
          'factura_a':  checkout.form.factura_a,
          'cuit': checkout.form.cuit,
          'domicilio': checkout.form.domicilio,
          'email': checkout.form.email,
          'nombre': checkout.form.nombre,
          'genero': checkout.form.genero,
          'nacimiento': checkout.form.nacimiento
        };
        checkout.order = {
          '_id': checkout.order._id,
          'discount': checkout.order.discount,
          'name': checkout.order.name,
          'final_price': checkout.order.final_price,
          'brand': checkout.order?.brand?.name,
          'corporativo': {
            '_id': checkout.order.corporativo?._id,
            'name': checkout.order.corporativo?.name,
            'cupon': checkout.order.corporativo?.cupon,
            'validez-cupon': checkout.order.corporativo?.['validez-cupon']
          }
        };
        fetch( config.url.API_URL +'/process_payment/' + planPath + location.search, {
          method: 'POST',
          headers: {'Content-Type': 'application/json; charset=utf-8'},
          body: JSON.stringify(checkout)
        })
          .then(async response => {
            const data = await response.json();
            if(checkout.order?.brand?.toLowerCase() === 'aon') {
              if (data.status === 'approved') {
                window.opener.postMessage({data: data}, '*');
                window.close();
              } else {
                let snackError = { text: data.message, status: 'error'};
                setError(snackError);
              }
            } else {
              if(data.status === 'approved') {
                setStatus('success');
                navigate('/response/success', { replace: true });
              } else if (data.status === 'in_process'){
                setStatus('in_process');
                navigate('/response/in_process', { replace: true });
              } else {
                let snackError = { text: data.message, status: 'error'};
                setError(snackError);
              }
            }
            setLoading(false);
          })
          .catch((e) => {
            fetch(config.url.API_URL + '/send_email', {
              method: 'POST',
              headers: {'Content-Type': 'application/json; charset=utf-8'},
              body: JSON.stringify(checkout)
            })
              .then(async response => {
                if(response.ok){
                  console.log('mail enviado', response);
                } else {
                  console.log('Fallo al enviar un mail', response);
                }
              });
            setStatus('error');
            navigate('/response/error', { replace: true });
          });
      }
    } else {
      let snackError = {text: 'Debes completar el formulario', status: 'error'};
      setError(snackError);
      event.preventDefault();
      setLoading(false);
    }
  };

  return error;
}