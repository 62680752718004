import { createTheme } from '@mui/material';

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#202128',
    },
    secondary: {
      main: '#FFDB2A',
      contrastText: '#ffffff'
    },
    info: {
      main: '#007668',
    },
    background: {
      paper: '#2B2C33', 
      default: '#202128',
    },
    backgroundInput: {
      main: '#5B5C66'
    }
  },
  typography: {
    fontFamily: 'Raleway, sans-serif',
  },
  components: {
    MuiMenu: {
      styleOverrides: {
        list: {
          backgroundColor: '#2B2C33 !important',
        },
      },
    },
    MuiNativeSelect:{
      styleOverrides: {
        option: {
          backgroundColor: '#2B2C33 !important',
        },
      },
    },
    // Applied to the <li> elements
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 15,
          fontWeight: '600'
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderBottomColor: 'rgba(0,0,0,0)',
        },
        '&:not': {
          backgroundColor: '#FFF'
        }
      },
    },
    MenuList:{
      styleOverrides:{
        root: {
          background: '#000'
        }
      }
    },
  },
});

const brandTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#202128', //#0f9688
    },
    secondary: {
      main: '#FFDB2A',
      contrastText: '#ffffff'
    },
    info: {
      main: '#007668',
    },
    background: {
      paper: '#F9FAFC', //#0f9688
      default: '#F9FAFC',
    },
  },
  typography: {
    fontFamily: 'Raleway, sans-serif',
  },
  components: {
    MuiMenu: {
      styleOverrides: {
        list: {
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
      },
    },
    // Applied to the <li> elements
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 12,
          background: 'rgba(0,0,0,0)',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderBottomColor: 'rgba(0,0,0,0)',
        },
        '&:not': {
          backgroundColor: '#FFF'
        }
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundColor: 'rgb(255, 255, 255)',
        },
      },
    },
    MenuList:{
      styleOverrides:{
        root: {
          background: 'white'
        }
      }
    },
  },
});

export default brandTheme;
