import * as Yup from "yup";
import moment from 'moment'

let LettersSpaces = /^[a-zA-Z\s]*$/;

let currentYear = moment().format('YY');

let validYear = currentYear - 1 


export const cardSchema = Yup.object().shape({
  cardHolderName: Yup.string()
    .required("El nombre del titular es requerido")
    .matches(LettersSpaces, "El nombre solo puede contener letras y espacios"),
  cardNumber: Yup.string()
  .required("Debes ingresar una tarjeta")
  .matches(/^[0-9]*$/, 'La tarjeta solo debe tener números')
  .min(14, "Tu tarjeta debe tener por lo menos 14 dígitos")
  .max(18, "Tu tarjeta no debe tener más de 18 dígitos"),
  cardExpirationMonth: Yup.string()
  .required("Este campo es requerido")
  .test('len', 'El campo debe tener 2 numeros', val => val.length === 2)
  .test('len', 'El mes ingresado no es válido', val => Number(val) < 13 && Number(val) > 0),
  cardExpirationYear: Yup.lazy((value) => {
    if(value === ''){
      return Yup.string()
    }
    return Yup.number()
      .required('Este campo es requerido')
      .min(2, 'El año de vencimiento debe tener 2 numeros')
      .moreThan(validYear, 'El año ingresado es invalido')
  }),
  securityCode: Yup.string()
    .required("Este campo es requerido")
    .min(3, "Tu CVV tiene que tener al menos 3 numeros")
    .max(4, "Tu CVV no puede ser mayor a 4"),
});

export const defaultValues = {
  cardholderName: "",
  cardNumber: "",
  cardExpirationMonth: "",
  cardExpirationYear: "",
  securityCode: "",
};
