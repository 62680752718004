import { makeStyles } from '@mui/styles';

export default makeStyles({
  textField: {
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 0,
    margin: 0,
    fontWeight: 500,
  },
  input: {
    
    '&::before': {
      content: '\'\'',
      borderBottom: 0,
    },
    '&::after': {
      content: '\'\'',
      borderBottom: 0,
    },
    '&:hover::before': {
      content: '\'\'',
      borderBottom: 0,
    },
    '&:hover': {
      backgroundColor: 'none'
    },
  },
  selectInput: {
    '&::before': {
      content: '\'\'',
      borderBottom: 0,
    },
    '&::after': {
      content: '\'\'',
      borderBottom: 0,
    },
    '&:hover:not(.Mui-disabled)::before': {
      content: '\'\'',
      borderBottom: 0,
    },
    '&:hover': {
      borderBottom: 0,
    } 
  }
});
