import React, { useEffect, useContext } from 'react';
//UI Components
import {
  Box,
  Button,
  Card,
  CardContent,
  FormHelperText,
  InputAdornment,
  InputBase,
} from '@mui/material';
//React Hook-Forms
import { useForm, Controller } from 'react-hook-form';
//Yup - schema validation library
import { yupResolver } from '@hookform/resolvers/yup';
import { discountSchema, discountValues } from './discountSchema';
//Icon
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
//Styles
import { styled } from '@mui/styles';
import { unstable_styleFunctionSx } from '@mui/system';
import { OrderContext } from '../../../../../providers/orderContext';
import AppliedChip from './AppliedChip';
import { config } from '../../../../../utils/constants/Constants';

const Form = styled('form')(unstable_styleFunctionSx);

const Discount = () => {
  const { checkout, setCheckout, checkoutError, setCheckoutError } = useContext(OrderContext);
  const order = checkout.order;
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(discountSchema),
    defaultValues: discountValues,
    reValidateMode: 'onSubmit',
  });

  const discountInput = watch('discount');

  useEffect(() => {
    if (discountInput === '') {
      setCheckoutError({ ...checkoutError, discount: false });
    }
  }, [discountInput]);


  const onSubmit = (data, e) => {
    let submitData = {
      'corporativo': {
        '_id': checkout.order.corporativo?._id
      },
      'price': checkout.order.price
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      body: JSON.stringify(submitData)
    };
    fetch(config.url.API_URL + '/discount/' + data.discount, requestOptions).then(async response => {
      const data = await response.json();
      if (response.ok) {
        setCheckout({
          ...checkout, order: {
            ...order,
            final_price: data.final_prize,
            discount: {
              ...order.discount,
              cupon_applied: true,
              cupon_code: data.cupon_code,
              monto_absoluto: data.monto_absoluto,
              'porcentaje-cupon': data['porcentaje-cupon'],
              concepto: data.concepto,
              fecha_vigencia: data.fecha_vigencia,
              duracion: data.duracion,
              final_price: data.final_prize
            }
          }
        });
        window.history.replaceState(null, 'New Page', `?code=${data.cupon_code}`);
        setCheckoutError({ ...checkoutError, discount: false });
      } else {
        const error = (data && data.msg) || response.status;
        return Promise.reject(error);
      }
    }
    )
      .catch(error => {
        if (error.status === 'invalid') {
          setCheckoutError({ ...checkoutError, discount: error.msg });
        } else {
          setCheckoutError({ ...checkoutError, discount: 'Ese descuento es invalido' });
        }
        console.error(error);
      });
  };
  const onError = (errors, e) => {
    //setLoading(true);
    console.log('error', errors, e);
  };

  const handleDelete = () => {
    console.info('You clicked the delete icon.');
    setCheckout({
      ...checkout, order: {
        ...order, discount: {
          ...order.discount,
          cupon_applied: false,
          cupon_code: undefined,
        }, final_price: Math.round(checkout.order.price * (1 - checkout.order.discount['porcentaje-descuento'] / 100))
      }
    });
    window.history.replaceState(null, 'New Page', '?code=');
  };


  return (
    // <Card elevation={0}>
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        {order.discount['porcentaje-descuento'] != null && order.discount['porcentaje-descuento'] >= 1 && (
          <AppliedChip text={order?.corporativo?.name} percentage={order.discount['porcentaje-descuento']} haberes={order?.corporativo['descuento-haberes']} />)
        }
      </Box>
      {!order.discount?.cupon_applied && order.discount.cupon && (
        <Form
          onSubmit={handleSubmit(onSubmit, onError)}
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: { xs: 'start', md: 'center' },
          }}
        >
          <Controller
            name="discount"
            control={control}
            render={({ field }) => (
              <>
                <InputBase
                  sx={{
                    width: { xs: '100%' },
                    bgcolor: 'rgba(255, 255, 255, 0.1)',
                    p: 1,
                    borderRadius: 1,
                    border: errors.discount && '1.5px solid #d32f2f',
                  }}
                  placeholder="Codigo Promocional"
                  inputProps={{ 'aria-label': 'search google maps' }}
                  endAdornment={
                    <InputAdornment position="end">
                      <Button
                        variant="text"
                        color="secondary"
                        onClick={handleSubmit(onSubmit, onError)}
                      >
                        Aplicar
                      </Button>
                    </InputAdornment>
                  }
                  {...field}
                />
              </>
            )}
          />
          {checkoutError.discount && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#FFE5E5',
                mx: { xs: 0, md: 1 },
                my: { xs: 1, md: 0 },
                p: 1,
                borderRadius: 1,
              }}
            >
              <ErrorOutlineOutlinedIcon color="error" sx={{ pr: 1 }} />
              {checkoutError.discount && (
                <FormHelperText error={true} filled={true}>
                  {checkoutError.discount}
                </FormHelperText>
              )}
            </Box>
          )}
        </Form>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        {order.discount?.cupon_applied && (
          <AppliedChip
            text={order.discount.cupon_code}
            percentage={order.discount['porcentaje-cupon']}
            canDelete={true}
            handleDelete={handleDelete}
            haberes={order?.corporativo['descuento-haberes']}
            cupon={true}
          />
        )}
      </Box>
    </>
    // </Card>
  );
};

export default Discount;
