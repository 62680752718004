import React, { useContext } from 'react';
//Routing
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { NotFound, Purchase, Response } from '.';
import { initialData } from '../api/planSample';
import { OrderContext } from '../providers/orderContext';
import { Helmet } from 'react-helmet';
import fitness from '../favicons/favicon.ico';
import car from '../favicons/car.ico';
import { useTheme } from '@mui/material';
import AlertSnackbar from '../components/Alert/AlertSnackbar';
import ModalAfk from '../components/ModalAfk/ModalAfk';

const MainView = () => {
  const { step, status, checkout: { order } } = useContext(OrderContext);
  const { palette } = useTheme();

  return (
    <Router>
      <div style={{ backgroundColor: palette.background.paper }}>
        <Helmet>
          <title>{order?.brand?.name.toLowerCase() === 'sportclub' ? 'SportClub' : order?.brand?.name.toLowerCase() === 'aon' ? 'Verificá tu medio de pago' : 'Checkout'}</title>
          <link rel="icon" href={order?.brand?.name.toLowerCase() === 'aon' ? car : fitness} />
        </Helmet>
        <Routes>
          <Route path="paso:number">
            <Route
              exact
              path=":planId"
              element={<Purchase initialData={initialData} />}
            />
          </Route>
          {step >= 2 & status !== false && <Route exact path="/response/:status" element={<Response />} />}
          <Route path="*" element={<NotFound />} />
        </Routes>
        <AlertSnackbar />
        <ModalAfk />
      </div>
    </Router>
  );
};

export default MainView;
