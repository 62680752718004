import axios from 'axios';

const BASE_PATH = process.env.REACT_APP_CHECKOUT_BACKEND_URL; //https://test.pagar.club/

export const instance = axios.create({
  baseURL: BASE_PATH,
  timeout: 15000,
});

const catchError = (e) => {
  console.error('Error Catch', e, e.response);
  return e;
};


export default {
  get: (url, params = {}) => {
    return instance.get(url, { params: {...params} }).then(data => data);
  },

  get_one: (url, params) => {
    return instance.get(url, params).then(data => data).catch(catchError);
  },

  put: (url, data, config) => {
    return instance.put(url, data, config).then((data) => data).catch(catchError);
  },

  post: (url, params) => {
    return instance.post(url, params).then(d => d);
  },

  delete: (url, params) => {
    return instance.delete(url, {params: {...params}}).then((data) => data).catch(catchError);
  }
};

