import React from 'react';
import Button from '@mui/material/Button';
import { useConnect } from '@thirdweb-dev/react';
import { ConnectWallet as ConnectWalletComponent } from '@thirdweb-dev/react';
import logo from '../../favicons/favicon.ico';

const ButtonBase = ({ children, sx, ...rest }) => {
  return (
    <Button
      color='secondary'
      fullWidth
      sx={{
        borderRadius: 1,
        padding: '9px',
        color: '#000',
        fontWeight: '700',
        textTransform: 'none',
        fontSize: '15px',
        ...sx
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

const Feedback = ({ children, loading, disabled, title, titleLoading = 'Cargando...', onPress, ...rest }) => {

  return (
    <ButtonBase
      disabled={loading || disabled}
      variant="contained"
      size="small"
      color='secondary'
      onClick={onPress}
      {...rest}
    >
      {
        loading ? titleLoading : title
      }
    </ButtonBase>
  );
};

const ConnectWallet = () => {
  const connect = useConnect();

  // async function handleConnect() {
  //   try {
  //     const wallet = await connect(walletConfig);

  //     console.log('connected to', wallet);
  //   } catch (e) {
  //     console.error('failed to connect', e);
  //   }
  // }

  return (
    <>
      <ConnectWalletComponent
        modalSize='compact'
        btnTitle="Conecta tu Wallet"
        fullWidth
        style={{
          width: '100%',
          borderRadius: '6px',
          padding: '12px 25px',
          color: '#2B2C33',
          fontWeight: '700',
          textTransform: 'none',
          fontSize: '15px',
          backgroundColor: '#FFD300'
        }}
        modalTitle="Conectar"
        modalTitleIconUrl={logo}
        switchToActiveChain={true}
        showAllWallets
        showThirdwebBranding={false}
        signInButton={false}
        locale={'es-ES'}
      />
    </>
  );
};

ButtonBase.Feedback = Feedback;
ButtonBase.ConnectWallet = ConnectWallet;

export default ButtonBase;