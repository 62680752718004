import React, { useContext } from 'react';
//UI
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
//Icons
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { OrderContext } from '../../../../../providers/orderContext';
import { Box } from '@mui/material';


const AppliedChip = ({ text, percentage, handleDelete, canDelete, haberes, cupon = false }) => {

  const { checkout: { order: { discount } } } = useContext(OrderContext);

  const duracion = `x ${discount.duracion} ${discount.duracion === 1 ? 'mes' : 'meses'}`;

  return (
    <Chip
      label={
        <Box>
          <Typography>{text}</Typography>
          {haberes === 0 && <Typography variant="subtitle2" fontWeight={300}>
            {percentage} % OFF Adicional {cupon && discount.duracion < 100 && duracion}
          </Typography>}
          {haberes > 0 && <Typography variant="subtitle2" fontWeight={300}>Descuento corporativo</Typography>}
        </Box>
      }
      sx={{
        justifyContent: 'flex-start',
        gap: 2,
        borderRadius: 1,
        fontSize: 15,
        px: 2,
        my: 1,
        py: 1,
        backgroundColor: 'rgba(255,255,255,0.1)',
        border: '1px solid rgba(255,255,255,0.3)',
        height: '100%',
        width: '100%',
      }}
      icon={<LocalOfferIcon />}
      color="secondary"
      variant="outlined"
      onDelete={handleDelete}
      deleteIcon={canDelete && <CloseOutlinedIcon sx={{ display: 'flex', justifySelf: 'flex-end' }} />}
    />
  );
};

export default AppliedChip;