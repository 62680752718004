import { Box, Button, Card, CardContent, CardHeader, Modal, Typography } from '@mui/material'
import React from 'react'
import { config } from '../../../../utils/constants/Constants';
import useMongo from '../../../../utils/hooks/useMongo';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    borderRadius: 3,
};

const BajaModal = ({ open, setOpen, subscripcion }) => {
    const { bajaSocio } = useMongo()

    const darBaja = async () => {
        const status = await bajaSocio(subscripcion._id)
        if (status === 'success') {
            setOpen(false)
        } else {
            window.alert('Hubo un error')
            setOpen(false)
        }
        console.log('Baja status', status)
    }

    return (
        <Modal open={open}>
            <Box sx={[style, { width: { xs: 300, md: 600 } }]}>
                <Card sx={{ p: { xs: 2, md: 4 }, bgcolor: 'white' }}>
                    <CardHeader disableSpacing title='¿Estás seguro que quieres darte de baja?' />
                    <CardContent sx={{ display: 'flex', flexDirection: 'column', alignContent: 'flex-start' }}>
                        <Typography>A continuación {subscripcion?.status === 'activo' ? 'daras de baja de tu membersia de SportClub' : 'revertiras la baja de tu membersia de SportClub'} ¿Deseas continuar?</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', pt: 3 }}>
                            <Button onClick={() => setOpen(false)}>Volver</Button>
                            <Button onClick={() => darBaja()} variant="contained" color={subscripcion?.status === 'activo' ? 'error' : 'success'} sx={{ ml: 2 }}>{subscripcion?.status === 'activo' ? 'Dar de Baja' : 'Revertir Baja'}</Button>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Modal>
    )
}

export default BajaModal