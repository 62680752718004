import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Controller, useForm, useFormContext, useWatch } from 'react-hook-form';
import { Button, Grid, Typography } from '@mui/material';
import useMercadoPago from '../../../../../utils/hooks/useMercadoPago';
import { CardInput, CustomInput, ErrorSnackBar } from '../../../../../components';
import { OrderContext } from '../../../../../providers/orderContext';
import { CuitFacturaA, Cvv, DNI, FacturaA, Month, Year } from '../../../../../components/Inputs/Inputs';
import ButtonBase from '../../../../../components/Buttons/ButtonBase';
import Text from '../../../../../components/Text/Text';
import Installments from '../Installments/Installments';

const PaymentCard = ({ control, errors, touchedFields, setLoading, loading, handleBack }) => {
  const { getValues } = useFormContext();
  const { setValue } = useForm();
  const valores = getValues();
  //Use Watch de react hook forms para trabajar sobre el cambio de numeros en la tarjeta
  const cardNumberWatch = useWatch({
    control,
    name: 'payment.cardNumber'
  });
  const facturaAWatch = useWatch({
    name: 'factura_a'
  });
  const documentType = useWatch({
    name: 'documento_tipo'
  });
  const [cardImage, setCardImage] = useState(null);
  const [installments, setInstallments] = useState(null);
  const { checkout } = useContext(OrderContext);
  const error = useMercadoPago({ setCardImage, setInstallments, setLoading, cardNumberWatch });

  useEffect(() => {
    if (checkout.form.payment.cardNumber === '') {
      setCardImage(null);
    }
  }, [checkout.form.payment.cardNumber]);

  useEffect(() => {
    if (valores?.factura_a === false) {
      setValue('cuit', '');
    }
  }, [valores?.factura_a]);



  return (
    <Box sx={{ px: { xs: 2, md: 0 } }}>
      <Text.Subtitle sx={{ marginBottom: 2, color: '#DADADA' }}>Completá tus datos</Text.Subtitle>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            name="payment.cardHolderName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomInput
                label="Nombre en la tarjeta"
                id="form-checkout__cardholderName"
                errors={errors?.payment?.cardHolderName}
                field={field}
                borders={{
                  topRight: { xs: 10, md: 0 },
                  topLeft: 10,
                  bottomLeft: { xs: 0, md: 10 },
                  bottomRight: 0,
                }}
                width={{ xs: '100%' }}
                isValid={touchedFields?.payment?.cardHolderName}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="payment.identificationNumber"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <DNI
                width={{ xs: '100%' }}
                errors={errors}
                field={field}
                touchedFields={touchedFields}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="payment.cardNumber"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CardInput
                errors={errors?.payment?.cardNumber}
                field={field}
                touchedFields={touchedFields?.payment?.cardNumber}
                imageUrl={cardImage}
                id='form-checkout__cardNumber'
                borders={{ topRight: 10, topLeft: 10, bottomLeft: 0, bottomRight: 0 }}
                width="100%"
                lengthLimit={18}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="payment.cardExpirationMonth"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Month
                width={{ xs: '100%' }}
                errors={errors}
                field={field}
                touchedFields={touchedFields}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="payment.cardExpirationYear"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Year
                errors={errors}
                field={field}
                touchedFields={touchedFields}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="payment.securityCode"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Cvv
                errors={errors}
                field={field}
                touchedFields={touchedFields}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Installments
            errors={errors}
            control={control}
            installments={installments}
            id="form-checkout__installments"
            name="payment.installments"
            label="Cantidad de Cuotas"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Installments
            errors={errors}
            control={control}
            installments={installments}
            id="form-checkout__issuer"
            label="Banco Emisor"
            name="payment.issuer"
          />
        </Grid>
     
          <>
            <Grid item xs={12} md={8}>
              {/* <Controller
                name="factura_a"
                control={control}
                render={({ field }) => (
                  <FacturaA
                    errors={errors}
                    field={field}
                    touchedFields={touchedFields}
                  />
                )}
              /> */}
            </Grid>
            {(facturaAWatch === true && documentType !== 'cuit') && (
              <Grid item xs={12} md={4}>
                <Controller
                  name="cuit"
                  control={control}
                  render={({ field }) => (
                    <CuitFacturaA
                      errors={errors}
                      field={field}
                      touchedFields={touchedFields}
                      width={{ xs: '100%', md: '50%' }}
                    />
                  )}
                />
              </Grid>
            )}
          </>
    
        <Grid item xs={12} md={8}
          alignItems={'center'}
          justifyItems={'center'}
          sx={{ display: 'flex', alignItems: 'flex-end' }}
        >
          {/* <Button sx={{ color: '#fff' }} variant='text' onClick={handleBack}>
            <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>Atrás</Typography>
          </Button> */}
        </Grid>

        <Grid item xs={12} md={4} sx={{ marginTop: 1, }}>
          <ButtonBase.Feedback
            id='form-checkout__submit'
            loading={loading}
            type='submit'
            title={'Realizar el Pago'}
          />
        </Grid>

      </Grid>

      <input id="paymentMethodId" style={{ visibility: 'hidden' }}></input>
      <select
        name="cars"
        id="form-checkout__identificationType"
        style={{ display: 'none' }}
      ></select>

      {error.status !== '' && (
        <ErrorSnackBar open={true} message={error.text} status={error.status} />
      )}
    </Box>
  );
};

export default PaymentCard;
